import { getSectionArticles } from "../../../services/news";
import Article from "./article";
import React, { useEffect, useState } from "react";
import TopicArticle from "../forYou/articles/topicArticle";
import "../news.css";
import Button from "@mui/material/Button";

const Section = ({ topic, categories }) => {
  const [articles, set_articles] = useState([]);
  const [offset, setOffset] = useState(1);
  useEffect(() => {
    loadArticles();
  }, [offset]);

  const loadArticles = async () => {
    const news_articles = await getSectionArticles(categories, offset);

    set_articles(articles.concat(news_articles));
    // set_articles(articles.concat(news_articles));
  };

  return (
    <div
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <div>
        {articles.map((i) => {
          return (
            // i.image &&
            <div key={`article-${i.id}`}>
              <div className="webArticle">
                <Article key={i.id} article={i} />
              </div>
              <div className="mobileArticle">
                <TopicArticle key={`mobile-${i.id}`} newsArticle={i} />
              </div>
            </div>
          );
        })}
      </div>
      <span
        className="loadMore"
        onClick={async () => {
          setOffset(offset + 1);
        }}
      >
        Load more
      </span>
    </div>
  );
};
export default Section;
