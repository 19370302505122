import { useSearchParams } from "react-router-dom";
import * as React from "react";
import OttMain from "./ottMain";
import "./ott.css";
import TopicVideo from "../ott/ottMain/videos/topicVideo";
import "../ott/ottMain/ottTopics.css";
import { getTopicData } from "../../services/ott";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

const Ott = () => {
  const [searchParams] = useSearchParams();

  const [banners, setBanners] = React.useState([]);

  React.useEffect(() => {
    if (searchParams.get("genres")) {
      getGenreData();
    }
  }, []);

  const getGenreData = async () => {
    const bannersResponse = await getTopicData("Romance");
    setBanners(bannersResponse);
  };

  // const handleChange = (event = null, newValue = "") => {
  //   setValue(newValue);
  // };

  return (
    <div
      className="lexend-regular"
      style={{
        typography: "body1",
        padding: "0.2rem calc((102vw - 1200px) / 2)",
      }}
    >
      {banners && banners.length > 0 ? (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Breadcrumbs
            sx={{ padding: "1%" }}
            separator={<NavigateNextIcon fontSize="medium" />}
            aria-label="breadcrumb"
          >
            <Link underline="hover" color="inherit" href="/ott">
              OTT
            </Link>
            <Typography color="text.primary">Romance</Typography>
          </Breadcrumbs>
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            {Array(10)
              .fill(banners)
              .flat()
              // .slice(0, 10)
              .map((i) => {
                return (
                  // i.image && (
                  <div className="ottTopicVideo genreScreenVideo">
                    <TopicVideo key={i.id} ottVideo={i} />
                  </div>
                  // )
                );
              })}
          </div>
        </div>
      ) : (
        <OttMain />
      )}
    </div>
  );
};

export default Ott;
