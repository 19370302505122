import React, { useEffect, useState } from "react";
import HeadlineArticle from "./articles/headlineArcticle";
import { getNewsHeadlinesData } from "../../../services/news";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
    slidesToSlide: 3, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
    slidesToSlide: 2, // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
};

const Headlines = ({ switchTab }) => {
  const [headlines, setHeadlines] = useState([]);

  useEffect(() => {
    getHlData();
  }, []);

  const getHlData = async () => {
    const headlinesResponse = await getNewsHeadlinesData();
    setHeadlines(headlinesResponse);
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "1% 1%",
          marginTop: "1%",
        }}
      >
        <span style={{ fontSize: "18px", fontWeight: 500 }}>Headlines</span>
        <span
          style={{ color: "#0280FF", fontSize: "14px", cursor: "pointer" }}
          onClick={() => {
            switchTab(null, "LATEST");
          }}
        >
          View All
        </span>
      </div>

      {/* <div style={{ display: "flex", justifyContent: "space-between" }}> */}
      <div>
        <Carousel
          swipeable={false}
          draggable={false}
          // showDots={true}
          responsive={responsive}
          ssr={true} // means to render carousel on server-side.
          infinite={true}
          // autoPlay={this.props.deviceType !== "mobile" ? true : false}
          autoPlay={true}
          autoPlaySpeed={4000}
          keyBoardControl={true}
          customTransition="all .5"
          transitionDuration={500}
          containerClass="carousel-container"
          // removeArrowOnDeviceType={["tablet", "mobile"]}
          // deviceType={this.props.deviceType}
          // dotListClass="custom-dot-list-style"
          itemClass="carousel-item-padding-40-px"
        >
          {headlines.slice(0, 10).map((i) => {
            {
              return <HeadlineArticle key={i.id} newsArticle={i} />;
            }
          })}
        </Carousel>
      </div>
      {/* </div> */}
    </div>
  );
};

export default Headlines;
