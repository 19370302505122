import React from "react";
// import DownloadInput from "react-downloadd-input";
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import CloseIcon from "@mui/icons-material/Close";
import "@fontsource/lexend"; // Defaults to weight 400
import navigateNext from "../../assests/material-navigate-next.png";
import appleIcon from "../../assests/apple-2.svg";
import androidIcon from "../../assests/android-2.svg";
import Brand from "../../assests/DuniyaLogo.png";
import "./dialog.css";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function DownloadPopup({
  openDownload,
  handleDownloadClose,
  mobileNumber,
  error,
}) {
  const editDownloadPic = () => {};
  return (
    <>
      <Dialog
        sx={{
          "& .MuiTextField-root": { m: 2 },
          "& .MuiPaper-root": {
            borderRadius: "30px",
          },
        }}
        open={openDownload}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleDownloadClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <div
          className="alert-dialog"
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              padding: "5%",
            }}
          >
            <CloseIcon
              style={{ position: "absolute", top: "16px", right: "16px" }}
              onClick={handleDownloadClose}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              style={{
                margin: "2% 10% 5%",
              }}
              width="30%"
              alt="download_pic"
              src={Brand}
              onClick={editDownloadPic}
            />
            <span
              style={{
                margin: "0% 10% 5%",
                textAlign: "center",
                fontWeight: 300,
                fontSize: "15px",
              }}
            >
              Download the Mobile App & unlock the Full Potential of Dhuniya
            </span>
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              width: "80%",
              justifyContent: "space-between",
              margin: "2% 10%",
              padding: "2%",
              boxShadow: "0px 3px 6px #00000029",
              border: "1px solid #0280FF",
              borderRadius: "10px",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                height: "30px",
                cursor: "pointer",
              }}
              onClick={() => {
                window.location.href =
                  "https://play.google.com/store/apps/details?id=com.dhuniya.news.business.listings.classifieds.ott";
              }}
            >
              <img
                style={{
                  borderRadius: "50px",
                  margin: "1% 3%",
                }}
                width="10%"
                alt="download_pic"
                src={androidIcon}
              />
              <span style={{ fontSize: "13px", fontWeight: 500 }}>
                Get it on Android Playstore
              </span>
            </div>
            <img
              alt="next"
              style={{ width: "8px", padding: "1% 5%" }}
              src={navigateNext}
            ></img>
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              width: "80%",
              justifyContent: "space-between",
              margin: "2% 10%",
              padding: "2%",
              boxShadow: "0px 3px 6px #00000029",
              border: "1px solid #0280FF",
              borderRadius: "10px",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                height: "30px",
                cursor: "pointer",
              }}
              onClick={() => {
                window.location.href =
                  "https://apps.apple.com/in/app/dhuniya/id6478494902";
              }}
            >
              <img
                style={{
                  // borderRadius: "50px",
                  margin: "0% 3%",
                }}
                width="10%"
                alt="download_pic"
                src={appleIcon}
              />
              <span style={{ fontSize: "13px", fontWeight: 500 }}>
                Get it on Apple Appstore
              </span>
            </div>
            <img
              alt="next"
              style={{ width: "8px", padding: "1% 5%" }}
              src={navigateNext}
            ></img>
          </div>

          <span
            style={{
              fontSize: "13px",
              margin: "5% 5% 10% 5%",
              fontWeight: 300,
            }}
          >
            Enter the New World of Dhuniya today!
          </span>
        </div>
      </Dialog>
    </>
  );
}
