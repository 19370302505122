import React, { useEffect, useState } from "react";
import "@fontsource/lexend"; // Defaults to weight 400
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import LoginButton from "../../../login";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { useParams } from "react-router-dom";
import {
  getFullArticle,
  likeNewsArticle,
  dislikeNewsArticle,
  isArticleLiked,
} from "../../../../services/news";
import heart from "../../../../assests/heart.svg";
import default_article_image from "../../../../assests/default_article_image.jpeg";
import "../newsTopics.css";
import Topic from "../newsTopic";
import { useNavigate } from "react-router-dom";

const FullArticle = () => {
  const navigate = useNavigate();

  let { article_id } = useParams();
  const [article, set_article] = useState();
  const [likeCount, setLikeCount] = useState(0);
  const [liked, setLiked] = useState(false);
  const [mobileOS, setMobileOS] = useState("Web");

  useEffect(() => {
    getMobileOperatingSystem();
    if (article_id && mobileOS !== "Web") {
      if (mobileOS === "Android") {
        window.location.href =
          "https://play.google.com/store/apps/details?id=com.dhuniya.news.business.listings.classifieds.ott";
      } else if (mobileOS === "iOS") {
        window.location.href =
          "https://apps.apple.com/in/app/dhuniya/id6478494902";
      }
    }
    loadArticle();
  }, [mobileOS, article_id]);

  const loadArticle = async () => {
    const news_articles = await getFullArticle(article_id);
    set_article(news_articles);
    setLikeCount(news_articles.likes_count);
    setLiked(await isArticleLiked(news_articles.id));
  };

  const getMobileOperatingSystem = () => {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;
    if (/android/i.test(userAgent)) {
      setMobileOS("Android");
    }
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      setMobileOS("iOS");
    }
  };

  const updateLike = async () => {
    if (liked) {
      setLiked(false);
      setLikeCount(likeCount - 1);
      dislikeNewsArticle(article.id);
    } else {
      setLiked(true);
      setLikeCount(likeCount + 1);
      likeNewsArticle(article.id);
    }
  };

  return (
    <div
      className="lexend-regular"
      style={{
        typography: "body1",
        padding: "0.2rem calc((102vw - 1200px) / 2)",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Breadcrumbs
          sx={{ padding: "2%" }}
          separator={<NavigateNextIcon fontSize="medium" />}
          aria-label="breadcrumb"
        >
          <Link underline="hover" color="inherit" href="/news">
            NEWS
          </Link>
          {article?.categories?.[0] && (
            <Link
              underline="hover"
              color="inherit"
              href={`/news?category=${article.categories?.[0]}`}
            >
              {article.categories?.[0]}
            </Link>
          )}
          <Typography color="text.primary">{article?.ref_id}</Typography>
        </Breadcrumbs>
        <LoginButton className="loginButton" />
      </div>
      {article && (
        <div
          className="fullArticleBlock"
          style={{ display: "flex", alignItems: "flex-start" }}
        >
          <div
            className="fullArticle"
            style={{
              width: "50%",
              padding: "1% 2%",
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <strong style={{ fontSize: "larger" }}>{article.title}</strong>
            {/* {article.image && (
              <img
                style={{ height: "40vh", margin: "2% 0%" }}
                src={article.image ? article.image : default_article_image}
              />
            )} */}
            {article.video && article.video.length > 0 ? (
              <video style={{ height: "40vh", margin: "2% 0%" }} controls>
                <source src={article?.video?.[0]} type="video/mp4" />
              </video>
            ) : (
              article.image &&
              article.image.length > 0 && (
                <img
                  style={{ height: "40vh", margin: "2% 0%" }}
                  src={article.image ? article.image : default_article_image}
                />
              )
            )}
            <span
              // className="fullArticleDescription"
              style={{ fontSize: "medium", paddingBottom: "1%" }}
            >
              {article.description}
            </span>
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <img
                src={heart}
                style={{ height: "18px", margin: "10px 5px" }}
                onClick={() => {
                  updateLike();
                }}
              />
              <span className="likeCount">{likeCount} </span>
            </div>
          </div>

          <div
            className="fullArticle"
            style={{
              width: "50%",
            }}
          >
            <Topic
              topic_name={article?.categories?.[0].toUpperCase()}
              categories={article?.categories?.[0].toUpperCase()}
              title={`More in ${article?.categories?.[0]}`}
              count={4}
              switchTab={() => {
                navigate(`/news?category=${article.categories?.[0]}`);
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
};
export default FullArticle;
