import React, { useEffect } from "react";
import {
  // makeStyles,
  Slider,
  // withStyles,
  Button,
  Tooltip,
  Popover,
  Grid,
} from "@mui/material";
import { makeStyles, withStyles } from "@mui/styles";
import { styled } from "@mui/material/styles";
import {
  FastForward,
  FastRewind,
  Pause,
  PlayArrow,
  SkipNext,
  VolumeUp,
  VolumeOff,
} from "@mui/icons-material";
import "./Control.css";
import VideoSettingsIcon from "@mui/icons-material/VideoSettings";
import Forward10Icon from "@mui/icons-material/Forward10";
import Replay10Icon from "@mui/icons-material/Replay10";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";

const useStyles = makeStyles({
  volumeSlider: {
    width: "100px",
    color: "#9556CC",
  },

  bottomIcons: {
    color: "#999",
    padding: "12px 8px",

    "&:hover": {
      color: "#fff",
    },
  },
});

// const PrettoSlider = withStyles({
//   root: {
//     height: "20px",
//     color: "#9556CC",
//     display: "flex",
//     justifyContent: "center",
//     alignItems: "center",
//   },
//   thumb: {
//     height: 20,
//     width: 20,
//     backgroundColor: "#9556CC",
//     border: "2px solid currentColor",
//     marginTop: -3,
//     marginLeft: -12,
//     "&:focus, &:hover, &$active": {
//       boxShadow: "inherit",
//     },
//   },
//   active: {},
//   valueLabel: {
//     left: "calc(-50% + 4px)",
//   },
//   track: {
//     height: 5,
//     borderRadius: 4,
//     width: "100%",
//   },
//   rail: {
//     height: 5,
//     borderRadius: 4,
//   },
// })(Slider);

const ToBeStyledSlider = ({ className, ...props }) => {
  <Slider {...props} classes={{ tooltip: className }} />;
};

// const PrettoSlider = styled(ToBeStyledSlider)(({ theme }) => ({
//   root: {
//     height: "20px",
//     color: "#9556CC",
//     display: "flex",
//     justifyContent: "center",
//     alignItems: "center",
//   },
//   thumb: {
//     height: 20,
//     width: 20,
//     backgroundColor: "#9556CC",
//     border: "2px solid currentColor",
//     marginTop: -3,
//     marginLeft: -12,
//     "&:focus, &:hover, &$active": {
//       boxShadow: "inherit",
//     },
//   },
//   active: {},
//   valueLabel: {
//     left: "calc(-50% + 4px)",
//   },
//   track: {
//     height: 5,
//     borderRadius: 4,
//     width: "100%",
//   },
//   rail: {
//     height: 5,
//     borderRadius: 4,
//   },
// }));

const PrettoSlider = styled(Slider)({
  height: 5,
  color: "#FFFFFF",
  "& .MuiSlider-track": {
    border: "none",
  },
  "& .MuiSlider-thumb": {
    height: 16,
    width: 16,
    backgroundColor: "#fff",
    border: "2px solid currentColor",
    "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
      boxShadow: "inherit",
    },
    "&:before": {
      display: "none",
    },
  },
  "& .MuiSlider-valueLabel": {
    lineHeight: 1.2,
    fontSize: 12,
    background: "unset",
    padding: 0,
    width: 32,
    height: 32,
    borderRadius: "50% 50% 50% 0",
    backgroundColor: "#52af77",
    transformOrigin: "bottom left",
    transform: "translate(50%, -100%) rotate(-45deg) scale(0)",
    "&:before": { display: "none" },
    "&.MuiSlider-valueLabelOpen": {
      transform: "translate(50%, -100%) rotate(-45deg) scale(1)",
    },
    "& > *": {
      transform: "rotate(45deg)",
    },
  },
});

const Control = ({
  onPlayPause,
  playing,
  onRewind,
  onForward,
  showElement,
  played,
  onSeek,
  onSeekMouseUp,
  onVolumeChangeHandler,
  onVolumeSeekUp,
  volume,
  mute,
  onMute,
  duration,
  currentTime,
  onMouseSeekDown,
  fullScreen,
  controlRef,
  handleClickFullscreen,
  handleExitFullscreen,
  videoPlayerRef,
  onChangeBitrate,
  currentLevel,
}) => {
  const classes = useStyles();

  let fontSize =
    window.innerWidth && window.innerWidth > 760 ? "medium" : "small";
  if (fullScreen) {
    fontSize = "large";
  }
  const [resolution, setResolution] = React.useState(currentLevel);

  const handleChange = (event) => {
    setResolution(event.target.value);
    onChangeBitrate(event);
  };

  return (
    <>
      <div
        className="control_Container"
        ref={controlRef}
        style={{
          opacity: showElement ? 1 : 0,
          color: "#fff",
          // height: "85%"
        }}
      >
        {/* <div className="top_container">
        <h2>Video PLayer</h2>
      </div> */}
        <div className="top_container">
          {/* <VideoSettingsIcon
            style={{
              zIndex: 1,
              backgroundColor: "gray",
              color: "#7b2cbf",
              width: "30px",
              height: "30px",
            }}
          /> */}
          <FormControl sx={{ color: "#fff", display: "block" }}>
            <Select
              className="resolutionDropdown"
              labelId="demo-simple-select-helper-label"
              id="demo-simple-select-helper"
              value={resolution}
              onChange={handleChange}
              sx={{
                color: "#fff",
                height: "25px",
              }}
            >
              {videoPlayerRef.current
                ?.getInternalPlayer("hls")
                ?.levels.map((level, id) => (
                  <MenuItem
                    sx={{
                      height: "25px",
                    }}
                    key={id}
                    value={id}
                  >
                    {`${level.height}p`}
                    {/* {level} */}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </div>
        <div className="mid__container">
          <div className="icon__btn">
            <Replay10Icon fontSize={fontSize} onClick={onRewind} />
          </div>

          <div className="icon__btn" onClick={onPlayPause}>
            {playing ? (
              <Pause fontSize={fontSize} />
            ) : (
              <PlayArrow fontSize={fontSize} />
            )}{" "}
          </div>

          <div className="icon__btn">
            <Forward10Icon fontSize={fontSize} onClick={onForward} />
          </div>
        </div>
        <div className="bottom__container">
          <div className="slider__container">
            <PrettoSlider
              min={0}
              max={100}
              value={played * 100}
              onChange={onSeek}
              onChangeCommitted={onSeekMouseUp}
              onMouseDown={onMouseSeekDown}
            />
          </div>
          <div className="control__box">
            <div className="inner__controls">
              <div style={{ display: "flex", alignItems: "center" }}>
                <div className="icon__btn" onClick={onPlayPause}>
                  {playing ? (
                    <Pause fontSize={fontSize} />
                  ) : (
                    <PlayArrow fontSize={fontSize} />
                  )}{" "}
                </div>

                {/* <div className="icon__btn">
                  <SkipNext fontSize={fontSize} />
                </div> */}

                <div className="icon__btn" onClick={onMute}>
                  {mute ? (
                    <VolumeOff fontSize={fontSize} />
                  ) : (
                    <VolumeUp fontSize={fontSize} />
                  )}
                </div>

                <Slider
                  sx={{
                    height: 3,
                    "& .MuiSlider-thumb": {
                      height: 12,
                      width: 12,
                    },
                  }}
                  className={`${classes.volumeSlider}`}
                  style={{
                    width: "100px",
                    color: "#fff",
                    marginRight: "10px",
                  }}
                  onChange={onVolumeChangeHandler}
                  value={volume * 100}
                  onChangeCommitted={onVolumeSeekUp}
                />

                <span>
                  {currentTime} : {duration}
                </span>
              </div>

              <div style={{ display: "flex" }}>
                {/* <Select onChange={onChangeBitrate}>
                  {videoPlayerRef.current
                    ?.getInternalPlayer("hls")
                    ?.levels.map((level, id) => (
                      <option key={id} value={id}>
                        {`${level.height}p`}
                      </option>
                    ))}
                </Select> */}

                {/* <FormControl
                  sx={{
                    m: 1,
                    backgroundColor: "#pink !important",
                    color: "#fff !important",
                  }}
                >
                  <Select
                    sx={{
                      color: "#fff",
                      backgroundColor: "#pink !important",
                      height: "25px",
                    }}
                    defaultValue={""}
                    onChange={onChangeBitrate}
                  >
                    {videoPlayerRef.current
                      ?.getInternalPlayer("hls")
                      ?.levels.map((level, id) => (
                        <MenuItem key={id} value={id}>
                          {`${level.height}p`}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl> */}

                {!fullScreen ? (
                  <FullscreenIcon
                    fontSize={fontSize}
                    onClick={handleClickFullscreen}
                    style={{
                      color: "#fff",
                      // width: "30px",
                      // height: "30px",
                    }}
                  />
                ) : (
                  <FullscreenExitIcon
                    fontSize={fontSize}
                    onClick={handleExitFullscreen}
                    style={{
                      color: "#fff",
                      // width: "30px",
                      // height: "30px",
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Control;
