const FindBusiness = () => {
  return (
    <div
      style={{
        typography: "body1",
        padding: "0.2rem calc((102vw - 1200px) / 2)",
      }}
    >
      <h1>Find Business</h1>
    </div>
  );
};
export default FindBusiness;
