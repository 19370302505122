import React from "react";

const BankAccountCard = ({ item }) => {
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        fontWeight: 400,
        fontSize: "14px",
      }}
    >
      <p style={{ fontWeight: "bold", fontSize: "16px" }}>
        Bank Account Details
      </p>
      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        <span style={{ width: "40%" }} className="details_title">
          Name
        </span>
        <span>:</span>
        <span
          style={{ width: "55%", marginBottom: "2%" }}
          className="details_description"
        >
          {item.full_name}
        </span>
      </div>

      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        <span style={{ width: "40%" }} className="details_title">
          Bank Account No
        </span>
        <span>:</span>
        <span
          style={{ width: "55%", marginBottom: "2%" }}
          className="details_description"
        >
          {item.account_number}
        </span>
      </div>

      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        <span style={{ width: "40%" }} className="details_title">
          Bank Name
        </span>
        <span>:</span>
        <span
          style={{ width: "55%", marginBottom: "2%" }}
          className="details_description"
        >
          {item.bank_name}
        </span>
      </div>

      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        <span style={{ width: "40%" }} className="details_title">
          IFSC Code
        </span>
        <span>:</span>
        <span
          style={{ width: "55%", marginBottom: "2%" }}
          className="details_description"
        >
          {item.ifsc_code}
        </span>
      </div>
    </div>
  );
};

export default BankAccountCard;
