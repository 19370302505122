import React from "react";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
// import Comment from "../../../../assests/ott/comment.png";
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import { dateConverter } from "../../../../utils";
import "../../ott.css";
import { useNavigate } from "react-router-dom";
import FullVideo from "./fullVideo";
import rating from "../../../../assests/movie_rating.svg";
import default_video_image from "../../../../assests/default_video_image.jpeg";
// import {
//   likeNewsVideo,
//   dislikeNewsVideo,
//   isVideoLiked,
// } from "../../../../services/ott";

const STRING_LEN = 75;
const BannerVideo = ({ ottVideo }) => {
  const navigate = useNavigate();
  const [openDownload, setOpenDownload] = React.useState(false);
  const [likeCount, setLikeCount] = React.useState(0);
  const [liked, setLiked] = React.useState(false);

  React.useEffect(() => {
    loadVideo();
  }, []);

  const loadVideo = async () => {
    setLikeCount(ottVideo.likes_count);
    // setLiked(await isVideoLiked(ottVideo.id));
  };

  const handleDownloadClose = () => {
    setOpenDownload(false);
  };

  // const updateLike = async () => {
  //   if (liked) {
  //     setLiked(false);
  //     setLikeCount(likeCount - 1);
  //     // dislikeNewsVideo(ottVideo.id);
  //   } else {
  //     setLiked(true);
  //     setLikeCount(likeCount + 1);
  //     // likeNewsVideo(ottVideo.id);
  //   }
  // };

  return (
    <>
      <div
        style={{
          display: "flex",
          height: "40vh",
          flexDirection: "column",
          boxShadow: "0px 3px 6px #00000029",
          alignItems: "flex-start",
          borderRadius: "5px",
          padding: "3% 5%",
          margin: "2%",
          // justifyContent: "space-between",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            cursor: "pointer",
            justifyContent: "space-between",
          }}
          onClick={() => {
            navigate(`/ott/${ottVideo.id}`);
          }}
        >
          <img
            alt="video_image"
            style={{
              height: "33vh",
              width: "100%",
              // margin: "3%",
              objectFit: "fill",
            }}
            // src={ottVideo.image}
            src={
              ottVideo.cover_image ? ottVideo.cover_image : default_video_image
            }
          />
          <span
            id={ottVideo.id}
            style={{
              // height: "20%",
              // width: "94%",
              margin: "1% 0%",
              fontSize: "16px",
              fontWeight: 300,
            }}
          >
            {ottVideo.title.length <= STRING_LEN
              ? ottVideo.title
              : ottVideo.title.substring(0, STRING_LEN) + "..."}

            {/* <a href={`/ott/${ottVideo.id}`}>read more</a> */}
          </span>
          <div
            style={{
              display: "flex",
              // justifyContent: "flex-start",
              // alignItems: "center",
            }}
          >
            <img src={rating} style={{ height: "20px" }} />
          </div>
        </div>
        {/* <div
        // style={{
        //   height: "10%",
        //   width: "94%",
        // }}
        >
          <div
            style={{
              display: "flex",
              height: "20px",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <img
                src={rating}
                style={{ height: "20px" }}
                // onClick={() => {
                //   updateLike();
                // }}
              />
              <span className="likeCount">{likeCount} </span>
            </div>
            <div style={{ display: "flex" }}>
              <div>
                <span className="half_opacity">
                  {dateConverter(ottVideo.created_date)}
                </span>
              </div>
            </div>
          </div>
        </div> */}
      </div>

      {openDownload && (
        <FullVideo
          ottVideo={ottVideo}
          openDownload={openDownload}
          handleDownloadClose={handleDownloadClose}
        />
      )}
    </>
  );
};
export default BannerVideo;
