import axios from "axios";

export const getCategoriesData = async () => {
  const response = await axios.get(`https://api.dhuniya.in/news/categories`);
  return response.data.info;
};

export const getNewsHeadlinesData = async () => {
  const response = await axios.get(
    `https://api.dhuniya.in/news/posts?limit=20&offset=1&order_by=-created_date`
  );
  return response.data.info;
};

export const getTopicData = async (categories) => {
  let query_params = "";
  if (categories) {
    query_params = `&categories=${categories}`;
  }
  const response = await axios.get(
    `https://api.dhuniya.in/news/posts?limit=20&offset=1&order_by=-created_date${query_params}`
  );
  return response.data.info;
};

export const getSectionArticles = async (categories, offset) => {
  let query_params = "";
  if (categories && categories !== "LATEST") {
    query_params = `&categories=${categories}`;
  }
  const response = await axios.get(
    `https://api.dhuniya.in/news/posts?limit=20&offset=${offset}&order_by=-created_date${query_params}`
  );
  return response.data.info;
};

export const getFullArticle = async (article_id) => {
  let query_params = "";
  if (article_id) {
    query_params = `ref_id=${article_id}`;
  } else {
    return {};
  }
  const response = await axios.get(
    `https://api.dhuniya.in/news/posts?${query_params}`
  );
  return response.data.info?.[0];
};

export const likeNewsArticle = async (news_id) => {
  // const tokenDetails = localStorage.getItem("dhu_user_token");
  // if (tokenDetails) {
  //   const access_token = JSON.parse(tokenDetails).access_token;
  //   const headers = { Authorization: `Bearer ${access_token}` };
  const formData = new FormData();
  formData.append("news_id", news_id);
  const response = await axios.post(
    `https://api.dhuniya.in/news/posts/like`,
    formData
    // { headers: headers }
  );
  const likedArticles = localStorage.getItem("dhu_user_news_likes");
  if (likedArticles) {
    const likedList = JSON.parse(likedArticles);
    likedList.push(news_id);
    localStorage.setItem("dhu_user_news_likes", JSON.stringify(likedList));
  } else {
    localStorage.setItem("dhu_user_news_likes", JSON.stringify([news_id]));
  }
  return response;
  // }
};

export const dislikeNewsArticle = async (news_id) => {
  // const tokenDetails = localStorage.getItem("dhu_user_token");
  // if (tokenDetails) {
  //   const access_token = JSON.parse(tokenDetails).access_token;
  //   const headers = { Authorization: `Bearer ${access_token}` };
  const formData = new FormData();
  formData.append("news_id", news_id);
  const response = await axios.post(
    `https://api.dhuniya.in/news/posts/dislike`,
    formData
    // { headers: headers }
  );
  const likedArticles = localStorage.getItem("dhu_user_news_likes");
  if (likedArticles) {
    const likedList = JSON.parse(likedArticles);
    const index = likedList.indexOf(news_id);
    if (index > -1) {
      likedList.splice(index, 1);
    }
    localStorage.setItem("dhu_user_news_likes", JSON.stringify(likedList));
  }
  return response;
  // }
};

export const isArticleLiked = async (news_id) => {
  const likedArticles = localStorage.getItem("dhu_user_news_likes");
  if (likedArticles) {
    const likedList = JSON.parse(likedArticles);
    const index = likedList.indexOf(news_id);
    if (index >= 0) {
      return true;
    }
    return false;
  }
  return false;
};
