import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import Slide from "@mui/material/Slide";
import CloseIcon from "@mui/icons-material/Close";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import "@fontsource/lexend"; // Defaults to weight 400
import "./dialog.css";
import Divider from "@mui/material/Divider";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function LoginPopup({
  openLogin,
  setOpenLogin,
  handleLoginClose,
  handleLoginSubmit,
  setMobileNumber,
  mobileNumber,
  error,
}) {
  const [checkBoxClicked, setCheckBoxClicked] = React.useState(false);

  const buttonStyles = checkBoxClicked
    ? {
        display: "flex",
        width: "80%",
        padding: "15px",
        margin: "10px",
        backgroundColor: "#077efa",
        color: "white",
        boxShadow: "0px 2px 4px #00000029",
        borderRadius: "50px",
      }
    : {
        display: "flex",
        width: "80%",
        padding: "15px",
        margin: "10px",
        backgroundColor: "#F3F6F9",
        color: "#D6D6D6",
        boxShadow: "0px 2px 4px #00000029",
        borderRadius: "50px",
      };

  const clickProceed = async () => {
    handleLoginSubmit(mobileNumber);
  };

  return (
    <>
      <Dialog
        sx={{
          "& .MuiTextField-root": { m: 2 },
          "& .MuiPaper-root": {
            borderRadius: "30px",
          },
        }}
        open={openLogin}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleLoginClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <div className="alert-dialog">
          <div
            style={{
              display: "flex",
              padding: "5%",
            }}
          >
            <span
              style={{
                margin: "auto",
                width: "50%",
                textAlign: "center",
                fontSize: "20px",
              }}
            >
              {" "}
              Welcome
            </span>
            <CloseIcon
              style={{ position: "absolute", top: "16px", right: "16px" }}
              onClick={handleLoginClose}
            />
          </div>
          <Divider style={{ width: "100%" }} />
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span
              style={{
                margin: "5% 10% 0%",
                fontSize: "14px",
              }}
            >
              You’re one step away form entering the New World of Dunia
            </span>

            <span
              style={{
                margin: "5% 10% 5%",
                opacity: "0.7",
                fontSize: "14px",
              }}
            >
              Enter your Mobile Number
            </span>
          </div>
          <div
            style={{ display: "flex", width: "100%", flexDirection: "column" }}
          >
            <input
              id="mobileNumber"
              maxLength="10"
              style={{
                padding: "3%",
                margin: "0% 10%",
                // width: "100%",
                fontSize: "20px",
              }}
              onChange={(event) => {
                // console.log(event.target.value);
                setMobileNumber(event.target.value);
              }}
            />
            {error && (
              <span
                style={{
                  padding: "3%",
                  margin: "0% 10%",
                  fontSize: "12px",
                  color: "red",
                }}
              >
                {error}
              </span>
            )}
          </div>
          <FormControlLabel
            sx={{
              margin: "5% 10%",
              opacity: "0.7",
              fontSize: "12px",
            }}
            required
            control={
              <Checkbox
                style={{
                  margin: "0%",
                }}
                onClick={() => {
                  setCheckBoxClicked(!checkBoxClicked);
                }}
              />
            }
            label={`I have read & agree to Terms of Use and Privacy Policy`}
          />

          <DialogActions sx={{ justifyContent: "center" }}>
            <Button
              style={buttonStyles}
              disabled={!checkBoxClicked}
              onClick={clickProceed}
            >
              <strong>Proceed</strong>
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    </>
  );
}
