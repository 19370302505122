import React from "react";
import { dateConverter } from "../../../../utils";
import "../../news.css";
import { useNavigate } from "react-router-dom";
import heart from "../../../../assests/heart.svg";
import default_article_image from "../../../../assests/default_article_image.jpeg";
import {
  likeNewsArticle,
  dislikeNewsArticle,
  isArticleLiked,
} from "../../../../services/news";

const STRING_LEN = 70;

const TopicArticle = ({ newsArticle }) => {
  const navigate = useNavigate();
  const [likeCount, setLikeCount] = React.useState(0);
  const [liked, setLiked] = React.useState(false);

  React.useEffect(() => {
    loadArticle();
  }, []);

  const loadArticle = async () => {
    setLikeCount(newsArticle.likes_count);
    setLiked(await isArticleLiked(newsArticle.id));
  };

  const updateLike = async () => {
    if (liked) {
      setLiked(false);
      setLikeCount(likeCount - 1);
      dislikeNewsArticle(newsArticle.id);
    } else {
      setLiked(true);
      setLikeCount(likeCount + 1);
      likeNewsArticle(newsArticle.id);
    }
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          margin: "5px 0px",
          padding: "10px",
          boxShadow: "0px 3px 6px #00000029",
          height: "80px",
        }}
      >
        <img
          style={{ width: "30%", cursor: "pointer" }}
          alt="article_image"
          // src={newsArticle.image}
          src={
            newsArticle.image && newsArticle.image.length > 0
              ? newsArticle.image
              : default_article_image
          }
          onClick={() => {
            navigate(`/news/${newsArticle.ref_id}`);
          }}
        />
        <div
          style={{
            width: "70%",
            display: "flex",
            flexWrap: "wrap",
            // "align-content": "space-between",
          }}
        >
          <div
            style={{ padding: "0px 10px", cursor: "pointer" }}
            onClick={() => {
              navigate(`/news/${newsArticle.ref_id}`);
            }}
          >
            <span>
              {newsArticle.description.length <= STRING_LEN
                ? newsArticle.description
                : newsArticle.description.substring(0, STRING_LEN) + "..."}
            </span>
            {/* <a style={{ color: "#0280FF" }} href="">
              {" "}
              read more
            </a> */}
          </div>

          <div
            style={{
              display: "flex",
              height: "20px",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <div style={{ display: "flex", padding: "0px 10px" }}>
              {/*<div style={{ marginRight: "10px" }}>
                <img style={{ height: "20px", }} src={Comment} />
                <span> 2</span>
              </div>*/}
              <div style={{ display: "flex", alignItems: "center" }}>
                {/* <FavoriteBorderOutlinedIcon sx={{ height: "25px" }} /> */}
                <img
                  src={heart}
                  style={{ height: "15px", marginRight: "5px" }}
                  onClick={() => {
                    updateLike();
                  }}
                />
                <span className="likeCount">{likeCount} </span>
              </div>
            </div>
            <div style={{ display: "flex" }}>
              <div>
                <span className="half_opacity">
                  {dateConverter(newsArticle.created_date)}
                </span>
              </div>
              {/* <BookmarkBorderIcon /> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TopicArticle;
