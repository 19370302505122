import React from "react";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
// import Comment from "../../../../assests/news/comment.png";
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import { dateConverter } from "../../../../utils";
import "../../news.css";
import { useNavigate } from "react-router-dom";
import FullArticle from "./fullArticle";
import heart from "../../../../assests/heart.svg";
import default_article_image from "../../../../assests/default_article_image.jpeg";
import {
  likeNewsArticle,
  dislikeNewsArticle,
  isArticleLiked,
} from "../../../../services/news";

const STRING_LEN = 75;
const HeadlineArticle = ({ newsArticle }) => {
  const navigate = useNavigate();
  const [openDownload, setOpenDownload] = React.useState(false);
  const [likeCount, setLikeCount] = React.useState(0);
  const [liked, setLiked] = React.useState(false);

  React.useEffect(() => {
    loadArticle();
  }, []);

  const loadArticle = async () => {
    setLikeCount(newsArticle.likes_count);
    setLiked(await isArticleLiked(newsArticle.id));
  };

  const handleDownloadClose = () => {
    setOpenDownload(false);
  };

  const updateLike = async () => {
    if (liked) {
      setLiked(false);
      setLikeCount(likeCount - 1);
      dislikeNewsArticle(newsArticle.id);
    } else {
      setLiked(true);
      setLikeCount(likeCount + 1);
      likeNewsArticle(newsArticle.id);
    }
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          height: "40vh",
          flexDirection: "column",
          boxShadow: "0px 3px 6px #00000029",
          alignItems: "center",
          borderRadius: "5px",
          padding: "3% 5%",
          margin: "2%",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            cursor: "pointer",
          }}
          onClick={() => {
            navigate(`/news/${newsArticle.ref_id}`);
          }}
        >
          <img
            alt="article_image"
            style={{
              height: "25vh",
              width: "100%",
              // margin: "3%",
              objectFit: "fill",
            }}
            // src={newsArticle.image}
            src={
              newsArticle.image && newsArticle.image.length > 0
                ? newsArticle.image
                : default_article_image
            }
          />
          <span
            id={newsArticle.id}
            style={{
              // height: "20%",
              // width: "94%",
              margin: "3% 0%",
              fontSize: "16px",
            }}
          >
            {newsArticle.description.length <= STRING_LEN
              ? newsArticle.description
              : newsArticle.description.substring(0, STRING_LEN) + "..."}

            {/* <a href={`/news/${newsArticle.id}`}>read more</a> */}
          </span>
        </div>
        <div
          style={{
            height: "10%",
            width: "94%",
          }}
        >
          <div
            style={{
              display: "flex",
              height: "20px",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <img
                src={heart}
                style={{ height: "15px", marginRight: "5px" }}
                onClick={() => {
                  updateLike();
                }}
              />
              <span className="likeCount">{likeCount} </span>
            </div>
            <div style={{ display: "flex" }}>
              <div>
                <span className="half_opacity">
                  {dateConverter(newsArticle.created_date)}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      {openDownload && (
        <FullArticle
          newsArticle={newsArticle}
          openDownload={openDownload}
          handleDownloadClose={handleDownloadClose}
        />
      )}
    </>
  );
};
export default HeadlineArticle;
