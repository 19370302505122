import React from "react";
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import CloseIcon from "@mui/icons-material/Close";
import "@fontsource/lexend"; // Defaults to weight 400
import navigateNext from "../../assests/material-navigate-next.png";
import appleIcon from "../../assests/apple-icon.png";
import androidIcon from "../../assests/android-icon.png";
import Brand from "../../assests/DuniyaLogo.png";
import "./dialog.css";
import Divider from "@mui/material/Divider";
import copy from "copy-to-clipboard";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ReferAndEarnPopup({
  openReferAndEarn,
  handleReferAndEarnClose,
  userDetails,
  mobileNumber,
  error,
}) {
  const [copyText, setCopyText] = React.useState(
    userDetails?.profile_details?.referral_code
  );
  const [copied, setCopied] = React.useState(false);

  const copyToClipboard = () => {
    copy(copyText);
    setCopied(true);
    // alert(`You have copied "${copyText}"`);
  };

  return (
    <>
      <>
        <Dialog
          sx={{
            "& .MuiTextField-root": { m: 2 },
            "& .MuiPaper-root": {
              borderRadius: "30px",
            },
          }}
          open={openReferAndEarn}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleReferAndEarnClose}
          aria-describedby="alert-dialog-slide-description"
        >
          <div className="alert-dialog">
            <div
              style={{
                display: "flex",
                padding: "5%",
              }}
            >
              <span
                style={{
                  margin: "auto",
                  width: "50%",
                  textAlign: "center",
                  fontSize: "18px",
                }}
              >
                {" "}
                Refer & Earn
              </span>
              <CloseIcon
                style={{ position: "absolute", top: "16px", right: "16px" }}
                onClick={handleReferAndEarnClose}
              />
            </div>
            <Divider style={{ width: "100%" }} />
            <div style={{ display: "flex", flexDirection: "column" }}>
              <span
                style={{
                  margin: "5% 10% 0%",
                  fontSize: "14px",
                }}
              >
                Share this code with your Friends & Start earning!
              </span>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  margin: "5% 10%",
                  padding: "5%",
                  fontSize: "14px",
                  boxShadow: "0px 3px 6px #00000029",
                  borderRadius: "5px",
                }}
              >
                <span>Refer & Earn ₹1 per person</span>
                <span
                  style={{ fontSize: "12px", fontWeight: 300, margin: "3% 0%" }}
                >
                  My referral code
                </span>
                <span
                  style={{
                    display: "flex",
                    border: "1px dashed #000000",
                    padding: "2%",
                    alignItems: "center",
                    width: "65%",
                    justifyContent: "space-between",
                  }}
                >
                  <span
                    style={{
                      margin: "1%",
                      padding: "1%",
                      color: "#0280FF",
                      fontWeight: 500,
                    }}
                  >
                    {copyText}
                  </span>
                  <span
                    style={{
                      fontSize: "12px",
                      fontWeight: 300,
                      margin: "1%",
                      padding: "1%",
                      cursor: "pointer",
                      opacity: copied ? "1" : "0.5",
                      color: copied ? "green" : "black",
                    }}
                    onClick={copyToClipboard}
                  >
                    {copied ? "Copied!" : "Copy"}
                  </span>
                </span>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  margin: "0% 10% 10%",
                  fontSize: "14px",
                  color: "#0A2647",
                }}
              >
                <span>Rules:</span>
                <span
                  style={{ marginTop: "2%", fontWeight: 200, fontSize: "12px" }}
                >
                  1. Your friend must download Dhuniya Mobile App & enter your
                  referral code after Sign-up.
                </span>
                <span
                  style={{ marginTop: "2%", fontWeight: 200, fontSize: "12px" }}
                >
                  2. Rs.1/- will be added to your Dhuniya Wallet for every
                  successful referral.
                </span>
              </div>
            </div>
          </div>
        </Dialog>
      </>
      {/* <Dialog
        sx={{
          "& .MuiTextField-root": { m: 2 },
          "& .MuiPaper-root": {
            borderRadius: "30px",
          },
        }}
        open={openReferAndEarn}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleReferAndEarnClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <div
          className="alert-dialog"
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              padding: "5%",
            }}
          >
            <CloseIcon
              style={{ position: "absolute", top: "16px", right: "16px" }}
              onClick={handleReferAndEarnClose}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <span style={{ margin: "0% 10% 5%", textAlign: "center" }}>
              Refer & Earn
            </span>
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              width: "80%",
              justifyContent: "space-between",
              margin: "2% 10%",
              padding: "2%",
              boxShadow: "0px 3px 6px #00000029",
              border: "1px solid #0280FF",
              borderRadius: "10px",
            }}
          >
            <img
              style={{
                borderRadius: "50px",
                margin: "1% 5%",
              }}
              width="10%"
              alt="referAndEarn_pic"
              src={androidIcon}
              onClick={editReferAndEarnPic}
            />
            <span style={{ fontSize: "12px" }}>
              Get it on Android Playstore
            </span>

            <img
              alt="next"
              style={{ width: "8px", padding: "1% 5%" }}
              src={navigateNext}
            ></img>
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              width: "80%",
              justifyContent: "space-between",
              margin: "2% 10%",
              padding: "2%",
              boxShadow: "0px 3px 6px #00000029",
              border: "1px solid #0280FF",
              borderRadius: "10px",
            }}
          >
            <img
              style={{
                borderRadius: "50px",
                margin: "1% 5%",
              }}
              width="10%"
              alt="referAndEarn_pic"
              src={appleIcon}
              onClick={editReferAndEarnPic}
            />
            <span style={{ fontSize: "12px" }}>Get it on Apple Appstore</span>

            <img
              alt="next"
              style={{ width: "8px", padding: "1% 5%" }}
              src={navigateNext}
            ></img>
          </div>

          <span style={{ fontSize: "12px", margin: "5% 5% 10% 5%" }}>
            Enter the New World of Dhuniya today!
          </span>
        </div>
      </Dialog> */}
    </>
  );
}
