import axios from "axios";

export const getCategoriesData = async () => {
  const response = await axios.get(`https://api.dhuniya.in/ott_service/genres`);
  return response.data.info;
};

export const getOttBannersData = async () => {
  const response = await axios.get(`https://api.dhuniya.in/ott_service/home`);
  return response.data.info;
};

export const getTopicData = async (categories) => {
  let query_params = "";
  if (categories) {
    query_params = `&categories=${categories}`;
  }
  const json_payload = {
    filter: "genre",
    value: categories,
    offset: 0,
  };
  const response = await axios.post(
    `https://api.dhuniya.in/ott_service/getMoviesByFilter`,
    json_payload
  );
  return response.data.info;
};

export const getSectionVideos = async (categories, offset) => {
  let query_params = "";
  if (categories && categories !== "LATEST") {
    query_params = `&categories=${categories}`;
  }
  const response = await axios.get(
    `https://api.dhuniya.in/news/posts?limit=20&offset=${offset}&order_by=-created_date${query_params}`
  );
  return response.data.info;
};

export const getFullVideo = async (video_id) => {
  // let query_params = "";
  // if (article_id) {
  //   query_params = `ref_id=${article_id}`;
  // } else {
  //   return {};
  // }
  const response = await axios.get(
    `https://api.dhuniya.in/ott_service/getMovieDetails/${video_id}`
  );
  return response.data.info;
};

export const likeOttVideo = async (ott_id) => {
  // const tokenDetails = localStorage.getItem("dhu_user_token");
  // if (tokenDetails) {
  //   const access_token = JSON.parse(tokenDetails).access_token;
  //   const headers = { Authorization: `Bearer ${access_token}` };
  const formData = new FormData();
  formData.append("ott_id", ott_id);
  const response = await axios.post(
    `https://api.dhuniya.in/news/posts/like`,
    formData
    // { headers: headers }
  );
  const likedVideos = localStorage.getItem("dhu_user_ott_likes");
  if (likedVideos) {
    const likedList = JSON.parse(likedVideos);
    likedList.push(ott_id);
    localStorage.setItem("dhu_user_ott_likes", JSON.stringify(likedList));
  } else {
    localStorage.setItem("dhu_user_ott_likes", JSON.stringify([ott_id]));
  }
  return response;
  // }
};

export const dislikeOttVideo = async (ott_id) => {
  // const tokenDetails = localStorage.getItem("dhu_user_token");
  // if (tokenDetails) {
  //   const access_token = JSON.parse(tokenDetails).access_token;
  //   const headers = { Authorization: `Bearer ${access_token}` };
  const formData = new FormData();
  formData.append("ott_id", ott_id);
  const response = await axios.post(
    `https://api.dhuniya.in/news/posts/dislike`,
    formData
    // { headers: headers }
  );
  const likedVideos = localStorage.getItem("dhu_user_ott_likes");
  if (likedVideos) {
    const likedList = JSON.parse(likedVideos);
    const index = likedList.indexOf(ott_id);
    if (index > -1) {
      likedList.splice(index, 1);
    }
    localStorage.setItem("dhu_user_ott_likes", JSON.stringify(likedList));
  }
  return response;
  // }
};

export const isVideoLiked = async (ott_id) => {
  const likedVideos = localStorage.getItem("dhu_user_ott_likes");
  if (likedVideos) {
    const likedList = JSON.parse(likedVideos);
    const index = likedList.indexOf(ott_id);
    if (index >= 0) {
      return true;
    }
    return false;
  }
  return false;
};
