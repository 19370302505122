import moment from "moment";

export const dateConverter = (startDate) => {
  const newStartDate = moment(startDate);
  const newEndDate = moment(new Date());

  //const result=moment(newEndDate).min(newStartDate)
  const duration = moment.duration(newEndDate.diff(newStartDate));
  let result = duration.asMinutes();
  if (result / 518400 >= 1) {
    result = Math.floor(result / 518400) + " years ago";
  } else if (result / 43200 >= 1) {
    result = Math.floor(result / 43200) + " months ago";
  } else if (result / 1440 >= 1) {
    result = Math.floor(result / 1440) + " days ago";
  } else if (result / 60 >= 1) {
    result = Math.floor(result / 60) + " hrs ago";
  } else {
    result = Math.floor(result) + " mins ago";
  }

  return result;
};
