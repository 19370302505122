import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import Brand from "../assests/DuniyaLogo.png";
import menu from "../assests/menu.svg";
import { Bars } from "./navbar/navbar_elements";
import Button from "@mui/material/Button";
import "./navbar.css";
import DownloadPopup from "../pages/login/downloadPopup";
import LoginButton from "../pages/login";
import Dhunia_logo from "../assests/vbc/dhunia_logo.png";
import { useNavigate, useLocation } from "react-router-dom";

const Navbar = () => {
  const [showNavbar, setShowNavbar] = useState(false);
  const [openDownload, setOpenDownload] = React.useState(false);
  const navigate = useNavigate();

  const location = useLocation();
  console.log(location.pathname.includes("vbc/"));

  const handleShowNavbar = () => {
    setShowNavbar(!showNavbar);
  };

  const handleDownloadClose = () => {
    setOpenDownload(false);
  };

  const handleDownloadOpen = () => {
    setOpenDownload(true);
  };

  return (
    <>
      {location.pathname.includes("vbc/") ? (
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img
            src={Dhunia_logo}
            alt="dhunia_logo"
            style={{ width: "150px" }}
            onClick={() => {
              navigate(`/`);
            }}
          />
        </div>
      ) : (
        <nav
          className="navbar lexend-regular"
          style={{ height: "110px", padding: "1rem calc(-600px + 51vw)" }}
        >
          <div className="container" style={{ height: "110px" }}>
            <div
              style={{ display: "flex", width: "100%", alignItems: "center" }}
            >
              <div className="topHeader">
                <div className="logo">
                  {/* <Brand /> */}
                  <img
                    src={Brand}
                    alt="dhunia_logo"
                    style={{ width: "80px", marginRight: "20px" }}
                    onClick={() => {
                      navigate(`/`);
                    }}
                  />
                  <span className="logoName">
                    {/* <img
                  src={Dhunia_logo}
                  alt="dhunia_logo"
                  style={{ marginRight: "20px" }}
                /> */}
                    <strong
                      style={{
                        fontSize: "28px",
                        fontStyle: '"Lexend, Bold" !important',
                      }}
                    >
                      DHUNIYA
                    </strong>
                  </span>
                </div>
                <div>
                  <Button
                    onClick={handleDownloadOpen}
                    className="downloadButton webDownload"
                  >
                    DOWNLOAD APP
                  </Button>
                </div>
              </div>
              <div className="menu-icon" onClick={handleShowNavbar}>
                {/* <Hamburger /> */}
                {/* <Bars /> */}
                <img src={menu} alt="dhunia_menu" style={{ width: "20px" }} />
              </div>
            </div>
            <div
              className={`nav-elements  ${showNavbar && "active"}`}
              style={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: "column",
              }}
            >
              <ul style={{ padding: "0px", margin: "10px 0px" }}>
                <li>
                  <NavLink to="/news" active={"true"}>
                    News
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/ott">Ott Videos</NavLink>
                </li>
                <li>
                  <NavLink to="/fB">Find Business</NavLink>
                </li>
              </ul>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <LoginButton className="mobileLogin" />
                <Button
                  style={{ margin: "5%" }}
                  onClick={handleDownloadOpen}
                  className="downloadButton mobileDownload"
                >
                  DOWNLOAD APP
                </Button>
              </div>
            </div>
            <div
              className="mobileHomeDownload"
              style={{
                marginBottom: "2%",
                display: "flex",
                border: "1px solid #A7AABC",
                padding: "10px",
              }}
            >
              <span style={{ fontSize: "14px", fontWeight: 700 }}>
                మీ దునియా.. మీ చేతుల్లో..
              </span>
              <Button
                style={{ fontSize: "10px" }}
                onClick={handleDownloadOpen}
                className="downloadButton"
              >
                DOWNLOAD APP
              </Button>
            </div>
          </div>
        </nav>
      )}
      {openDownload && (
        <DownloadPopup
          className=""
          openDownload={openDownload}
          setOpenDownload={setOpenDownload}
          userDetails={{}}
          handleDownloadClose={handleDownloadClose}
          error={""}
        />
      )}
    </>
  );
};

export default Navbar;
