import React, { useState, useEffect } from "react";
import OtpInput from "react-otp-input";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import Slide from "@mui/material/Slide";
import CloseIcon from "@mui/icons-material/Close";
import "@fontsource/lexend"; // Defaults to weight 400
import Lottie from "react-lottie-player";
import "./dialog.css";
import Divider from "@mui/material/Divider";
import lottieJson from "../../assests/OTPVerify.json";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function OtpPopup({
  openOtp,
  handleOtpClose,
  handleOtpSubmit,
  mobileNumber,
  error,
  targetDate,
}) {
  const [otp, setOtp] = useState("");
  const [resendOtp, setResendOtp] = useState(false);

  const countDownDate = targetDate;

  const [countDown, setCountDown] = useState(
    parseInt((countDownDate - new Date().getTime()) / 1000)
  );

  useEffect(() => {
    const interval = setInterval(() => {
      // console.log(
      //   "timer",
      //   parseInt((countDownDate - new Date().getTime()) / 1000)
      // );
      if (parseInt((countDownDate - new Date().getTime()) / 1000) < 0) {
        setResendOtp(true);
        clearInterval(interval);
      } else {
        setCountDown(parseInt((countDownDate - new Date().getTime()) / 1000));
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [countDownDate]);

  const buttonStyles =
    otp.length === 4
      ? {
          display: "flex",
          width: "90%",
          padding: "15px",
          margin: "0px 0px 30px",
          backgroundColor: "#077efa",
          color: "white",
          boxShadow: "0px 2px 4px #00000029",
          borderRadius: "50px",
        }
      : {
          display: "flex",
          width: "90%",
          padding: "15px",
          margin: "0px 0px 30px",
          backgroundColor: "#F3F6F9",
          color: "#D6D6D6",
          boxShadow: "0px 2px 4px #00000029",
          borderRadius: "50px",
        };

  const clickProceed = async () => {
    handleOtpSubmit(mobileNumber, otp);
  };

  const clickResendOtp = async () => {
    handleOtpSubmit(mobileNumber);
  };

  return (
    <>
      <Dialog
        sx={{
          "& .MuiTextField-root": { m: 2 },
          "& .MuiPaper-root": {
            borderRadius: "30px",
          },
        }}
        open={openOtp}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleOtpClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <div
          className="alert-dialog"
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              padding: "5%",
            }}
          >
            <span
              style={{
                margin: "auto",
                textAlign: "center",
                fontSize: "20px",
              }}
            >
              {" "}
              Enter OTP
            </span>
            <CloseIcon
              style={{ position: "absolute", top: "16px", right: "16px" }}
              onClick={handleOtpClose}
            />
          </div>
          <Divider style={{ width: "100%" }} />

          <Lottie
            loop
            animationData={lottieJson}
            play
            style={{ width: 75, height: 75, marginTop: "6%" }}
          />
          <span
            style={{
              margin: "5% 10% 5%",
              fontSize: "15px",
              opacity: "0.7",
            }}
          >
            {`OTP sent to +91 ${mobileNumber}`}
          </span>
          <OtpInput
            containerStyle={{
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
            }}
            inputStyle={{ width: "3em", height: "3em" }}
            value={otp}
            onChange={setOtp}
            numInputs={4}
            renderSeparator={
              <span style={{ margin: "1%", fontSize: "25px" }}>-</span>
            }
            renderInput={(props) => (
              <input
                style={{
                  padding: "5%",
                  margin: "2%",
                }}
                {...props}
              />
            )}
          />
          {error && (
            <span
              style={{
                padding: "3%",
                fontSize: "12px",
                color: "red",
              }}
            >
              {error}
            </span>
          )}
          <span
            style={{
              margin: "5% 10% 5%",
              fontSize: "13px",
              opacity: "0.7",
            }}
          >
            {`Did not receive OTP? Resend OTP (${countDown}s)`}
          </span>
          {resendOtp && (
            <DialogActions sx={{ justifyContent: "center", width: "80%" }}>
              <Button
                style={{
                  display: "flex",
                  width: "90%",
                  padding: "15px",
                  backgroundColor: "#1FB99E",
                  color: "white",
                  boxShadow: "0px 2px 4px #00000029",
                  borderRadius: "50px",
                }}
                onClick={clickResendOtp}
              >
                <strong>Resend OTP</strong>
              </Button>
            </DialogActions>
          )}
          <DialogActions sx={{ justifyContent: "center", width: "80%" }}>
            <Button
              style={buttonStyles}
              disabled={!(otp.length === 4)}
              onClick={clickProceed}
            >
              <strong>Proceed</strong>
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    </>
  );
}
