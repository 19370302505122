import React from "react";
import Headlines from "./headlines";
import Topic from "./newsTopic";
import "../news.css";
import { getCategoriesData } from "../../../services/news";

const ForYou = ({ switchTab }) => {
  const [categories, setCategories] = React.useState([]);

  const loadCategoriesData = async () => {
    const categoriesData = await getCategoriesData();
    setCategories(categoriesData.slice(0, 4));
  };

  React.useEffect(() => {
    loadCategoriesData();
  }, []);

  // const categories = [
  //   { displayname: "Politics", categories: "POLITICS" },
  //   { displayname: "Sports", categories: "SPORTS" },
  //   { displayname: "Movies", categories: "MOVIE" },
  //   { displayname: "Entertainment", categories: "ENTERTAINMENT" },
  //   // { displayname: "Art", categories: "ART" },
  // ];

  return (
    <>
      <Headlines switchTab={switchTab} />
      <div className="grid-container">
        {categories.map((category) => {
          return (
            <Topic
              key={category.name}
              id={category.name}
              topic_name={category.display_name}
              title={category.display_name}
              categories={category.name}
              switchTab={() => {
                switchTab(null, category.display_name.toUpperCase());
              }}
              count={3}
            />
          );
        })}
      </div>
    </>
  );
};

export default ForYou;
