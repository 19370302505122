import React from "react";

const UpiCard = ({ item }) => {
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        fontWeight: 400,
        fontSize: "14px",
      }}
    >
      <p style={{ fontWeight: "bold", fontSize: "16px" }}>UPI Details</p>
      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        <span style={{ width: "40%" }} className="details_title">
          {item.upi_app}
        </span>
        <span>:</span>
        <span
          style={{ width: "55%", marginBottom: "2%" }}
          className="details_description"
        >
          {item.upi_id}
        </span>
      </div>
    </div>
  );
};

export default UpiCard;
