import React from "react";
// import ProfileInput from "react-profiled-input";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import Slide from "@mui/material/Slide";
import CloseIcon from "@mui/icons-material/Close";
import "@fontsource/lexend"; // Defaults to weight 400
import verifyTick from "../../assests/verifyTick.png";
import editProfile from "../../assests/editProfilePic.svg";
import ProfileEditIcon from "../../assests/ProfileEditIcon.png";
import navigateNext from "../../assests/material-navigate-next.png";
import news_reporter from "../../assests/news_reporter.png";
import videos_upload from "../../assests/videos_upload.png";
import earnings from "../../assests/earnings.png";
import logoutIcon from "../../assests/logout.svg";
import "./dialog.css";
import DefaultPic from "../../assests/profile-pic.png";
import Divider from "@mui/material/Divider";
import appleIcon from "../../assests/apple-2.svg";
import androidIcon from "../../assests/android-2.svg";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ProfilePopup({
  userDetails,
  openProfile,
  handleProfileClose,
  handleLogout,
  handleWithdraw,
  mobileNumber,
  error,
}) {
  const buttonStyles = {
    display: "flex",
    width: "90%",
    padding: "15px",
    margin: "0px 0px 30px",
    backgroundColor: "#077efa",
    color: "white",
    boxShadow: "0px 2px 4px #00000029",
    borderRadius: "50px",
  };

  const clickProceed = async () => {
    handleLogout();
  };

  const editProfilePic = () => {
    // console.log("profile pic edit");
  };

  const editDownloadPic = () => {
    // console.log("download pic edit");
  };

  return (
    <>
      <Dialog
        sx={{
          "& .MuiTextField-root": { m: 2 },
          "& .MuiPaper-root": {
            borderRadius: "30px",
          },
        }}
        open={openProfile}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleProfileClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <div
          className="alert-dialog"
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              padding: "5%",
            }}
          >
            <span
              style={{
                margin: "auto",
                textAlign: "center",
                fontSize: "20px",
              }}
            >
              {" "}
              My Profile
            </span>
            <CloseIcon
              style={{ position: "absolute", top: "16px", right: "16px" }}
              onClick={handleProfileClose}
            />
          </div>
          <Divider style={{ width: "100%" }} />

          <div
            style={{
              width: "80%",
              display: "flex",
              alignItems: "center",
              // justifyContent: "space-between",
              margin: "5% 0%",
            }}
          >
            <div
              style={{
                display: "flex",
                width: "20%",
                marginRight: "6%",
                alignItems: "center",
              }}
            >
              <img
                style={{
                  borderRadius: "200px",
                  border: "1px solid #81b3ea",
                  aspectRatio: 1 / 1,
                  width: "100%",
                }}
                alt="profile_pic"
                src={
                  userDetails?.profile_details?.profile_image
                    ? userDetails?.profile_details?.profile_image
                    : DefaultPic
                }
                onClick={editProfilePic}
              />
              <img
                alt="edit"
                style={{
                  zIndex: "100",
                  width: "20px",
                  position: "relative",
                  top: "15px",
                  left: "-15px",
                }}
                src={editProfile}
              ></img>
            </div>
            <div>
              <div>
                <span style={{ fontSize: "16px", fontWeight: 500 }}>
                  {userDetails?.profile_details?.username}
                </span>
                <img
                  alt="tick"
                  style={{ marginLeft: "5px", width: "12px" }}
                  src={verifyTick}
                ></img>
              </div>
              <span style={{ fontWeight: 200, fontSize: "14px" }}>
                {userDetails?.profile_details?.mobile}
              </span>
            </div>

            {/* <img
              alt="edit"
              style={{ width: "20px", padding: "5%" }}
              src={ProfileEditIcon}
            ></img> */}
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "80%",
              margin: "2% 10%",
              padding: "2%",
              boxShadow: "0px 3px 6px #00000029",
            }}
          >
            <div
              style={{ display: "flex", width: "80%", alignItems: "center" }}
            >
              <img
                style={{
                  borderRadius: "50px",
                  margin: "1% 5%",
                }}
                width="40px"
                alt="profile_pic"
                src={earnings}
                onClick={editProfilePic}
              />
              <div style={{ display: "flex", flexDirection: "column" }}>
                <span
                  style={{
                    fontSize: "10px",
                    fontWeight: 200,
                    margin: "2px 0px",
                  }}
                >
                  My Earnings
                </span>
                <span
                  style={{ fontWeight: 600 }}
                >{`₹ ${userDetails?.profile_details?.earnings}`}</span>
              </div>
            </div>
            <Button
              style={{
                marginRight: "10px",
                fontSize: "12px",
                color: "black",
                border: "1px solid #EDEEF1",
              }}
              variant="outlined"
              onClick={handleWithdraw}
            >
              <strong>WITHDRAW</strong>
            </Button>
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              width: "80%",
              justifyContent: "space-between",
              margin: "2% 10%",
              padding: "2%",
              boxShadow: "0px 3px 6px #00000029",
              border: "1px solid #0280FF",
              borderRadius: "10px",
            }}
          >
            <div
              style={{ display: "flex", alignItems: "center", height: "30px" }}
            >
              <img
                style={{
                  borderRadius: "50px",
                  margin: "1% 3%",
                }}
                width="10%"
                alt="download_pic"
                src={androidIcon}
                onClick={editDownloadPic}
              />
              <span style={{ fontSize: "13px", fontWeight: 500 }}>
                Get it on Android Playstore
              </span>
            </div>
            <img
              alt="next"
              style={{ width: "8px", padding: "1% 5%" }}
              src={navigateNext}
            ></img>
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              width: "80%",
              justifyContent: "space-between",
              margin: "2% 10%",
              padding: "2%",
              boxShadow: "0px 3px 6px #00000029",
              border: "1px solid #0280FF",
              borderRadius: "10px",
            }}
          >
            <div
              style={{ display: "flex", alignItems: "center", height: "30px" }}
            >
              <img
                style={{
                  // borderRadius: "50px",
                  margin: "0% 3%",
                }}
                width="10%"
                alt="download_pic"
                src={appleIcon}
                onClick={editDownloadPic}
              />
              <span style={{ fontSize: "13px", fontWeight: 500 }}>
                Get it on Apple Appstore
              </span>
            </div>
            <img
              alt="next"
              style={{ width: "8px", padding: "1% 5%" }}
              src={navigateNext}
            ></img>
          </div>

          {/* <div
            style={{
              display: "flex",
              alignItems: "center",
              width: "80%",
              justifyContent: "space-between",
              margin: "2% 10%",
              padding: "2%",
              boxShadow: "0px 3px 6px #00000029",
            }}
          >
            <img
              style={{
                borderRadius: "50px",
                margin: "1% 5%",
              }}
              width="13%"
              alt="profile_pic"
              src={news_reporter}
              onClick={editProfilePic}
            />
            <span style={{ fontSize: "12px" }}>Become a News Reporter</span>

            <img
              alt="next"
              style={{ width: "8px", padding: "1% 5%" }}
              src={navigateNext}
            ></img>
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              width: "80%",
              justifyContent: "space-between",
              margin: "2% 10%",
              padding: "2%",
              boxShadow: "0px 3px 6px #00000029",
            }}
          >
            <img
              style={{
                borderRadius: "50px",
                margin: "1% 5%",
              }}
              width="13%"
              alt="profile_pic"
              src={videos_upload}
              onClick={editProfilePic}
            />
            <span style={{ fontSize: "12px" }}>Submit Videos</span>

            <img
              alt="next"
              style={{ width: "8px", padding: "1% 5%" }}
              src={navigateNext}
            ></img>
          </div> */}

          {/* <DialogActions sx={{ justifyContent: "center", width: "80%" }}> */}
          {/* <Button style={buttonStyles} onClick={clickProceed}>
              <strong>Logout</strong>
            </Button> */}
          <div
            style={{
              display: "flex",
              margin: "3% 0% 6% 0%",
              width: "80%",
              alignItems: "center",
              fontSize: "12px",
            }}
          >
            <span onClick={clickProceed} style={{ cursor: "pointer" }}>
              Logout
            </span>
            <img
              onClick={clickProceed}
              alt="logout"
              style={{
                width: "12px",
                padding: "5%",
                paddingLeft: "2%",
                cursor: "pointer",
              }}
              src={logoutIcon}
            ></img>
          </div>
          {/* </DialogActions> */}
        </div>
      </Dialog>
    </>
  );
}
