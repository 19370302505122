import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import Tabs, { tabsClasses } from "@mui/material/Tabs";
import { useSearchParams } from "react-router-dom";
import TabPanel from "@mui/lab/TabPanel";
import * as React from "react";
import ForYou from "./forYou";
import Section from "./topic/news_section";
import "./news.css";
import LoginButton from "../login";
import { getCategoriesData } from "../../services/news";

const News = () => {
  const [searchParams] = useSearchParams();
  const [value, setValue] = React.useState("FORYOU");
  const [categories, setCategories] = React.useState([]);

  const loadCategoriesData = async () => {
    const categoriesData = await getCategoriesData();
    categoriesData.unshift({
      display_name: "Latest",
      name: "LATEST",
    });
    setCategories(categoriesData);
  };

  React.useEffect(() => {
    loadCategoriesData();
    if (searchParams.get("category")) {
      setValue(searchParams.get("category"));
    }
  }, []);

  const handleChange = (event = null, newValue = "") => {
    setValue(newValue);
  };

  return (
    <div
      className="lexend-regular"
      style={{
        typography: "body1",
        padding: "0.2rem calc((102vw - 1200px) / 2)",
      }}
    >
      <TabContext value={value}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <TabList
            className="categoriesList"
            sx={{
              marginTop: "1%",
              fontFamily: "Lexend",
              width: "80%",
              [`& .${tabsClasses.scrollButtons}`]: {
                "&.Mui-disabled": { opacity: 0.3 },
              },
            }}
            onChange={handleChange}
            aria-label="lab API tabs example"
            variant="scrollable"
            scrollButtons
            allowScrollButtonsMobile
          >
            <Tab
              style={{ textTransform: "none", fontFamily: "Lexend" }}
              label="For You"
              value="FORYOU"
            />
            {categories.map((category) => {
              return (
                <Tab
                  key={category.display_name}
                  style={{ textTransform: "none", fontFamily: "Lexend" }}
                  label={category.display_name}
                  value={category.name.toUpperCase()}
                />
              );
            })}
          </TabList>
          <LoginButton className="loginButton" />
        </div>

        <TabPanel sx={{ padding: "0px" }} value="FORYOU">
          <ForYou switchTab={handleChange} />
        </TabPanel>
        {categories.map((category) => {
          return (
            <TabPanel
              key={category.display_name}
              sx={{ padding: "0px" }}
              value={category.name.toUpperCase()}
            >
              <Section
                topic={category.display_name}
                categories={category.name.toUpperCase()}
              />
            </TabPanel>
          );
        })}
      </TabContext>
    </div>
  );
};

export default News;
