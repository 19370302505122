import axios from "axios";

export const getOtp = async (mobileNumber) => {
  const formData = new FormData();
  formData.append("mobile", mobileNumber);

  const response = await axios.post(
    `https://api.dhuniya.in/userservice/authentication/sendotp`,
    formData
  );
  return response;
};

export const submitOtp = async (mobileNumber, otp) => {
  const payload = {
    mobile: mobileNumber,
    otp: otp,
  };
  const response = await axios.post(
    `https://api.dhuniya.in/userservice/authentication`,
    payload
  );
  return response;
};

export const passwordLogin = async (mobileNumber, password) => {
  const payload = {
    mobile: mobileNumber,
    password: password,
  };
  const response = await axios.post(
    `https://api.dhuniya.in/userservice/authentication`,
    payload
  );
  return response;
};

export const setPassword = async (mobileNumber, password) => {
  const tokenDetails = localStorage.getItem("dhu_user_token");
  if (tokenDetails) {
    const access_token = JSON.parse(tokenDetails).access_token;
    const headers = { Authorization: `Bearer ${access_token}` };
    const formData = new FormData();
    formData.append("password", password);

    const response = await axios.post(
      `https://api.dhuniya.in/userservice/authentication/password`,
      formData,
      { headers: headers }
    );
    return response;
  }
};

export const getFullUserDetails = async (ref_id) => {
  // const tokenDetails = localStorage.getItem("dhu_user_token");
  // if (tokenDetails) {
  // const access_token = JSON.parse(tokenDetails).access_token;
  // const headers = { Authorization: `Bearer ${access_token}` };
  const response = await axios.get(
    `https://api.dhuniya.in/business/cards?ref_id=${ref_id}`
    // { headers: headers }
  );
  return response;
  // }
};
