import React, { useEffect, useState } from "react";
import TopicVideo from "./videos/topicVideo";
import "./ottTopics.css";
import { getTopicData } from "../../../services/ott";

const Topic = ({ topic_name, categories, switchTab, title, count }) => {
  const [banners, setBanners] = useState([]);

  useEffect(() => {
    getTpcData();
  }, []);

  const getTpcData = async () => {
    // const bannersResponse = await getTopicData(categories);
    const bannersResponse = await getTopicData("Romance");
    setBanners(bannersResponse);
  };

  return (
    <>
      <div
        className="grid-item"
        id={`category-grid-item-${categories}`}
        key={`category-grid-item-${categories}`}
      >
        <div
          key={`category-${categories}`}
          id={`category-${categories}`}
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <strong style={{ fontSize: "18px", fontWeight: 500 }}>{title}</strong>
          <span
            style={{ color: "#0280FF", fontSize: "14px", cursor: "pointer" }}
            onClick={switchTab}
          >
            View All
          </span>
        </div>
        <div style={{ display: "flex", flexDirection: "column" }}>
          {/* {[1, 2, 3].map((i) => {
            return <TopicVideo />;
          })} */}
          {banners.slice(0, count).map((i) => {
            return (
              // i.image && (
              <div className="ottTopicVideo">
                <TopicVideo key={i.id} ottVideo={i} />
              </div>
              // )
            );
          })}
        </div>
      </div>
    </>
  );
};
export default Topic;
