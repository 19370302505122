import "./videoPlayer.css";
import ReactPlayer from "react-player";
import { Container } from "@mui/material";
import Control from "./Components/Control";
import { useState, useRef, useEffect } from "react";
import { formatTime } from "./format";
import screenfull from "screenfull";

let count = 0;

const VideoPlayer = ({ url }) => {
  const videoPlayerRef = useRef(null);
  const controlRef = useRef(null);
  const [showElement, setShowElement] = useState(true);
  const [videoState, setVideoState] = useState({
    playing: true,
    muted: false,
    volume: 0.5,
    playbackRate: 1.0,
    played: 0,
    seeking: false,
    buffer: true,
  });
  const [formatCurrentTime, setFormatCurrentTime] = useState("00:00");
  const [formatDuration, setFormatDuration] = useState("00:00");
  const [currentLevel, setCurrentLevel] = useState(0);

  //Destructuring the properties from the videoState
  const { playing, muted, volume, playbackRate, played, seeking, buffer } =
    videoState;

  //   const currentTime = videoPlayerRef.current
  //     ? videoPlayerRef.current.getCurrentTime()
  //     : "00:00";
  //   const duration = videoPlayerRef.current
  //     ? videoPlayerRef.current.getDuration()
  //     : "00:00";

  //   const formatCurrentTime = formatTime(currentTime);
  //   const formatDuration = formatTime(duration);

  useEffect(() => {}, []);

  useEffect(() => {
    const duration = videoPlayerRef.current
      ? videoPlayerRef.current.getDuration()
      : "00:00";
    setFormatDuration(formatTime(duration));

    if (videoPlayerRef.current.getCurrentTime() > 0) {
      const intervalId = setInterval(() => {
        //assign interval to a variable to clear it.
        const currentTime = videoPlayerRef.current
          ? videoPlayerRef.current.getCurrentTime()
          : "00:00";

        if (videoState?.playing) {
          setFormatCurrentTime(formatTime(currentTime));
          setVideoState({
            ...videoState,
            played: parseFloat(currentTime / duration),
          });
        }
      }, 1000);
      if (videoState?.played === 1) {
        clearInterval(intervalId);
      }
      return () => clearInterval(intervalId); //This is important
    }
  }, [videoState]);

  const playPauseHandler = () => {
    //plays and pause the video (toggling)
    setVideoState({ ...videoState, playing: !videoState.playing });
  };

  const rewindHandler = () => {
    //Rewinds the video player reducing 5
    videoPlayerRef.current.seekTo(videoPlayerRef.current.getCurrentTime() - 5);
  };

  const handleFastFoward = () => {
    //FastFowards the video player by adding 10
    videoPlayerRef.current.seekTo(videoPlayerRef.current.getCurrentTime() + 10);
  };

  //console.log("========", (controlRef.current.style.visibility = "false"));
  const progressHandler = (state) => {
    // if (count > 3) {
    //   console.log("close");
    //   controlRef.current.style.visibility = "hidden"; // toggling player control container
    // } else if (controlRef.current.style.visibility === "visible") {
    //   count += 1;
    // }

    if (!seeking) {
      setVideoState({ ...videoState, ...state });
    }
  };

  const seekHandler = (e, value) => {
    setVideoState({ ...videoState, played: parseFloat(value / 100) });
    videoPlayerRef.current.seekTo(parseFloat(value / 100));
  };

  const seekMouseUpHandler = (e, value) => {
    setVideoState({ ...videoState, seeking: false });
    videoPlayerRef.current.seekTo(value / 100);
  };

  const volumeChangeHandler = (e, value) => {
    const newVolume = parseFloat(value) / 100;

    setVideoState({
      ...videoState,
      volume: newVolume,
      muted: Number(newVolume) === 0 ? true : false, // volume === 0 then muted
    });
  };

  const volumeSeekUpHandler = (e, value) => {
    const newVolume = parseFloat(value) / 100;

    setVideoState({
      ...videoState,
      volume: newVolume,
      muted: newVolume === 0 ? true : false,
    });
  };

  const muteHandler = () => {
    //Mutes the video player
    setVideoState({ ...videoState, muted: !videoState.muted });
  };

  const onSeekMouseDownHandler = (e) => {
    setVideoState({ ...videoState, seeking: true });
  };

  const mouseMoveHandler = (event) => {
    // onMouseOver={(event) => {
    // console.log("Mouse Over", showElement);

    if (showElement) {
      setTimeout(function () {
        setShowElement(false);
      }, 5000);
    } else {
      //   console.log("Mouse Over", showElement);
      setShowElement(true);
    }
    // if (!showElement ) {
    //   console.log("Mouse Over", showElement);
    //   setShowElement(true);
    //   let timeObject = new Date();
    //     const milliseconds = 5 * 1000; // 10 seconds = 10000 milliseconds
    //     timeObject = new Date(timeObject.getTime() + milliseconds);
    //     setNextHoverTime(timeObject)
    // } else {
    //   setTimeout(function () {
    //     setShowElement(false);
    //   }, 5000);
    // }

    //   }}
    // count = 0;
  };

  const bufferStartHandler = () => {
    // console.log("Bufering.......");
    setVideoState({ ...videoState, buffer: true });
  };

  const bufferEndHandler = () => {
    // console.log("buffering stoped ,,,,,,play");
    setVideoState({ ...videoState, buffer: false });
    const internalPlayer = videoPlayerRef.current?.getInternalPlayer("hls");
    if (!internalPlayer?.currentLevel) {
      if (internalPlayer) {
        const hls_levels =
          videoPlayerRef.current?.getInternalPlayer("hls")?.levels;
        // console.log(hls_levels);
        internalPlayer.currentLevel = hls_levels?.[hls_levels.length - 1].id;
      }
    }
  };

  const onChangeBitrate = (event) => {
    // videoPlayerRef.current
    //   ?.getInternalPlayer("hls")
    //   ?.levels.map((level, id) => console.log(level, id));
    const internalPlayer = videoPlayerRef.current?.getInternalPlayer("hls");
    if (internalPlayer) {
      // currentLevel expect to receive an index of the levels array
      internalPlayer.currentLevel = event.target.value;
      setCurrentLevel(event.target.value);
    }
  };

  const handleHideElement = () => {
    setShowElement(false);
  };

  const handleClickFullscreen = () => {
    // console.log("fullScreen clicked");
    screenfull.request(document.querySelector(".player__wrapper"));

    // screenfull.request(document.querySelector(".control_Container"));
  };

  const handleExitFullscreen = () => {
    screenfull.exit(document.querySelector(".player__wrapper"));
    // screenfull.request(document.querySelector(".control_Container"));
  };

  return (
    <div className="video_container">
      {/* <div>
        <h2>React player</h2>
      </div> */}
      {/* Quality:
      <select onChange={onChangeBitrate}>
        {videoPlayerRef.current
          ?.getInternalPlayer("hls")
          ?.levels.map((level, id) => (
            <option key={id} value={id}>
              {`${level.height}p`}
            </option>
          ))}
      </select>
      <button onClick={handleClickFullscreen}>Fullscreen</button> */}
      {/* <Container maxWidth="md" justify="center"> */}
      <div className="player__wrapper" onMouseMove={mouseMoveHandler}>
        <ReactPlayer
          ref={videoPlayerRef}
          className="player"
          url={url}
          width="100%"
          height="100%"
          playing={playing}
          volume={volume}
          muted={muted}
          // controls={true}
          onProgress={progressHandler}
          onBuffer={bufferStartHandler}
          onBufferEnd={bufferEndHandler}
          scrollableAncestor="window"
        />
        {/* {buffer && <p>Loading</p>} */}

        <Control
          showElement={showElement}
          videoPlayerRef={videoPlayerRef}
          controlRef={controlRef}
          onPlayPause={playPauseHandler}
          playing={playing}
          onRewind={rewindHandler}
          onForward={handleFastFoward}
          played={played}
          onSeek={seekHandler}
          onSeekMouseUp={seekMouseUpHandler}
          volume={volume}
          onVolumeChangeHandler={volumeChangeHandler}
          onVolumeSeekUp={volumeSeekUpHandler}
          mute={muted}
          onMute={muteHandler}
          playRate={playbackRate}
          duration={formatDuration}
          currentTime={formatCurrentTime}
          fullScreen={screenfull.isFullscreen}
          handleClickFullscreen={handleClickFullscreen}
          handleExitFullscreen={handleExitFullscreen}
          onMouseSeekDown={onSeekMouseDownHandler}
          onChangeBitrate={onChangeBitrate}
          currentLevel={currentLevel}
        />
      </div>
      {/* </Container> */}
    </div>
  );
};

export default VideoPlayer;
