import * as React from "react";
import Button from "@mui/material/Button";
import "@fontsource/lexend"; // Defaults to weight 400
import "./dialog.css";

import LoginPopup from "./loginPopup";
import OtpPopup from "./otpPopup";
import PasswordPopup from "./passwordPopup";
import ProfilePopup from "./profilePopup";
import DownloadPopup from "./downloadPopup";
import {
  getOtp,
  submitOtp,
  setPassword,
  passwordLogin,
} from "../../services/login";
import PasswordLogin from "./passwordLogin";
import DefaultPic from "../../assests/profile-pic.png";
import ReferAndEarnPopup from "./referAndEarn";

export default function LoginButton({ className, openLoginPopup }) {
  const [mobileNumber, setMobileNumber] = React.useState("");
  const [error, setError] = React.useState("");
  const [openLogin, setOpenLogin] = React.useState(
    openLoginPopup ? openLoginPopup : false
  );
  const [openOtp, setOpenOtp] = React.useState(false);
  const [openPasswordLogin, setOpenPasswordLogin] = React.useState(false);
  const [openPassword, setOpenPassword] = React.useState(false);
  const [openProfile, setOpenProfile] = React.useState(false);
  const [openDownload, setOpenDownload] = React.useState(false);
  const [openReferAndEarn, setOpenReferAndEarn] = React.useState(false);
  const [userDetails, setUserDetails] = React.useState();
  const [userPasswordDetails, setUserPasswordDetails] = React.useState();

  React.useEffect(() => {
    let data = localStorage.getItem("dhunia_userDetails");
    if (data && JSON.parse(data)?.profile_details) {
      setUserDetails(JSON.parse(data));
    }
  }, []);

  const handleLoginClickOpen = () => {
    setError("");
    setOpenLogin(true);
  };
  const handleLoginClose = () => {
    setOpenLogin(false);
    setError("");
  };

  const handleLoginSubmit = async () => {
    setError("");
    // API call to login, on success open otp
    const response = await getOtp(mobileNumber);
    if (response?.data && response?.data?.success) {
      if (response?.data?.info?.is_login_with_password) {
        // is_login_with_password = true // enter password to login
        const userData = response?.data?.info;
        setUserPasswordDetails({
          username: userData.username,
          profile_image: userData.profile_image,
          mobile: mobileNumber,
        });
        setOpenLogin(false);
        setOpenPasswordLogin(true);
      } else {
        // is_login_with_password = false // waits for OTP to login
        setOpenLogin(false);
        setOpenOtp(true);
      }
    } else {
      // handle error login
      setError(response?.data?.error);
    }
  };

  const handleOtpClose = () => {
    setOpenOtp(false);
    setError("");
  };

  const handleOtpSubmit = async (mobileNumber, otp) => {
    setError("");
    // API call to validate otp
    const response = await submitOtp(mobileNumber, otp);
    // console.log(response);
    //   return response;
    //   "info": {
    //     "is_set_password": true,
    //     "refresh_token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoicmVmcmVzaCIsImV4cCI6MTcxNTExMzkyMywiaWF0IjoxNzA5OTI5OTIzLCJqdGkiOiI5YTcwYzc0YThiMDU0YTlhOTQyMDkyZjJlZDNlZmVhYyIsInVzZXJfaWQiOjYzfQ._Nq2wSXMtEgiDb-na-bmh-Fg4R-0c1BI9g6Jy64Fq_s",
    //     "access_token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNzEyNTIxOTIzLCJpYXQiOjE3MDk5Mjk5MjMsImp0aSI6IjNhZTU0NWM0ZDY4MjQ3NWRiNmEyZjZjYTFkYjEyMzc0IiwidXNlcl9pZCI6NjN9.qp05D5tv7ylEY9WZOUEXNsm551Pf5IBmHJer8MW63jQ"
    // }
    if (response?.data && response?.data?.success) {
      // set access token and refresh token
      if (response?.data?.info?.is_set_password) {
        localStorage.setItem(
          "dhu_user_token",
          JSON.stringify(response?.data?.info)
        );
        setOpenOtp(false);
        setOpenPassword(true);
      }
    } else {
      // handle error otp
      setError(response?.data?.description);
    }
  };

  const handlePasswordClose = () => {
    setOpenPassword(false);
    setError("");
  };

  const handlePasswordLoginClose = () => {
    setOpenPasswordLogin(false);
    setError("");
  };

  const handlePasswordLogin = async (mobile, password) => {
    setError("");
    const response = await passwordLogin(mobile, password);
    // console.log(response);
    if (response?.data && response?.data?.success) {
      if (response?.data?.info?.profile_details) {
        localStorage.setItem(
          "dhu_user_token",
          JSON.stringify({
            refresh_token: response?.data?.info?.refresh_token,
            access_token: response?.data?.info?.access_token,
          })
        );
        localStorage.setItem(
          "dhunia_userDetails",
          JSON.stringify({
            profile_details: response?.data?.info?.profile_details,
          })
        );

        setOpenPasswordLogin(false);

        window.location.reload();
      }
    } else {
      // handle error otp
      setError(response?.data?.description);
    }
  };

  const openProfilePopup = () => {
    // console.log("profile clicked");
    setOpenProfile(true);
  };

  const handleProfileClose = () => {
    setOpenProfile(false);
    setError("");
  };

  const handleProfileSubmit = () => {
    // API call to validate otp
    setOpenProfile(false);
  };

  const handleLogout = () => {
    // API call to logout
    localStorage.removeItem("dhunia_userDetails");
    localStorage.removeItem("dhu_user_token");
    localStorage.removeItem("dhu_user_news_likes");
    setOpenProfile(false);
    window.location.reload();
  };

  const handleDownloadClose = () => {
    setOpenDownload(false);
  };

  const handleReferAndEarnClose = () => {
    setOpenReferAndEarn(false);
  };

  const handleReferAndEarnOpen = () => {
    setOpenReferAndEarn(true);
  };

  const handleWithdraw = () => {
    setOpenProfile(false);
    setOpenDownload(true);
  };

  const handlePasswordSubmit = async (mobileNumber, password) => {
    setError("");
    // API call to set user password
    const response = await setPassword(mobileNumber, password);
    // console.log(response);
    if (response?.data && response?.data?.success) {
      setOpenPassword(false);
      handlePasswordLogin(mobileNumber, password);
      // localStorage.setItem(
      //   "dhunia_userDetails",
      //   JSON.stringify({
      //     profile_details: {
      //       id: 24,
      //       full_name: "charan",
      //       username: "GUESTQAE8WD",
      //       profile_image:
      //         "https://dhunia.s3.amazonaws.com/public/others/f1bd7e2ce83a431e8a7f733302081a28_R.png",
      //       email: null,
      //       mobile: 8096675403,
      //       gender: "MALE",
      //       dob: "2002-02-18",
      //       earnings: 0,
      //       total_earnings: 0,
      //       referral_code: "KARTHIK1",
      //     },
      //   })
      // );
      // window.location.reload();
    } else {
      // handle error
      setError(response?.data?.description);
    }
  };

  return (
    <>
      <div className={className}>
        {!userDetails ? (
          <Button
            style={{ fontWeight: 900 }}
            variant="outlined"
            onClick={handleLoginClickOpen}
          >
            Login / Sign-Up
          </Button>
        ) : (
          <div style={{ display: "flex", alignItems: "center" }}>
            <Button
              style={{ marginRight: "10px" }}
              variant="outlined"
              onClick={() => {
                handleReferAndEarnOpen();
              }}
            >
              <strong>Refer & Earn</strong>
            </Button>
            <img
              style={{ borderRadius: "20px", border: "2px solid #81b3ea" }}
              width="35px"
              alt="profile_pic"
              src={
                userDetails?.profile_details?.profile_image
                  ? userDetails?.profile_details?.profile_image
                  : DefaultPic
              }
              onClick={openProfilePopup}
            />
          </div>
        )}
      </div>

      {openLogin && (
        <LoginPopup
          openLogin={openLogin}
          setOpenLogin={setOpenLogin}
          handleLoginClose={handleLoginClose}
          handleLoginSubmit={handleLoginSubmit}
          mobileNumber={mobileNumber}
          setMobileNumber={setMobileNumber}
          error={
            /^\d{10}$/.test(mobileNumber) || mobileNumber.length === 0
              ? ""
              : "Please enter a valid mobile number"
          }
        />
      )}

      {openOtp && (
        <OtpPopup
          openOtp={openOtp}
          setOpenOtp={setOpenOtp}
          handleOtpClose={handleOtpClose}
          handleOtpSubmit={handleOtpSubmit}
          mobileNumber={mobileNumber}
          error={error}
          targetDate={new Date().getTime() + 30000}
        />
      )}

      {openPasswordLogin && (
        <PasswordLogin
          openPasswordLogin={openPasswordLogin}
          setOpenPasswordLogin={setOpenPasswordLogin}
          userDetails={userPasswordDetails}
          handlePasswordLoginClose={handlePasswordLoginClose}
          handlePasswordLogin={handlePasswordLogin}
          mobileNumber={mobileNumber}
          error={error}
        />
      )}

      {openPassword && (
        <PasswordPopup
          openPassword={openPassword}
          setOpenPassword={setOpenPassword}
          handlePasswordClose={handlePasswordClose}
          handlePasswordSubmit={handlePasswordSubmit}
          mobileNumber={mobileNumber}
          error={error}
          setError={setError}
        />
      )}

      {openProfile && (
        <ProfilePopup
          openProfile={openProfile}
          setOpenProfile={setOpenProfile}
          userDetails={userDetails}
          handleProfileClose={handleProfileClose}
          handleLogout={handleLogout}
          handleWithdraw={handleWithdraw}
          mobileNumber={mobileNumber}
          error={""}
        />
      )}

      {openDownload && (
        <DownloadPopup
          openDownload={openDownload}
          setOpenDownload={setOpenDownload}
          userDetails={userDetails}
          handleDownloadClose={handleDownloadClose}
          mobileNumber={mobileNumber}
          error={""}
        />
      )}

      {openReferAndEarn && (
        <ReferAndEarnPopup
          openReferAndEarn={openReferAndEarn}
          setOpenReferAndEarn={setOpenReferAndEarn}
          userDetails={userDetails}
          handleReferAndEarnClose={handleReferAndEarnClose}
          mobileNumber={mobileNumber}
          error={""}
        />
      )}
    </>
  );
}
