import React from "react";

const QRCard = ({ item }) => {
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        fontWeight: 400,
        fontSize: "14px",
      }}
    >
      <p style={{ fontWeight: "bold", fontSize: "16px" }}>UPI QR codes</p>
      <strong>{item.upi_app}</strong>
      <img width={"80%"} src={item.qr_code} alt="qr_code" />
    </div>
  );
};

export default QRCard;
