import React from "react";
import { dateConverter } from "../../../../utils";
import "../../ott.css";
import { useNavigate } from "react-router-dom";
import default_video_image from "../../../../assests/default_video_image.jpeg";
import rating from "../../../../assests/movie_rating.svg";

// import {
//   likeNewsVideo,
//   dislikeNewsVideo,
//   isVideoLiked,
// } from "../../../../services/ott";

const STRING_LEN = 70;

const TopicVideo = ({ ottVideo }) => {
  const navigate = useNavigate();
  const [likeCount, setLikeCount] = React.useState(0);
  const [liked, setLiked] = React.useState(false);

  React.useEffect(() => {
    loadVideo();
  }, []);

  const loadVideo = async () => {
    setLikeCount(ottVideo.likes_count);
    // setLiked(await isVideoLiked(ottVideo.id));
  };

  // const updateLike = async () => {
  //   if (liked) {
  //     setLiked(false);
  //     setLikeCount(likeCount - 1);
  //     dislikeNewsVideo(ottVideo.id);
  //   } else {
  //     setLiked(true);
  //     setLikeCount(likeCount + 1);
  //     likeNewsVideo(ottVideo.id);
  //   }
  // };

  return (
    <>
      <div
        style={{
          display: "flex",
          margin: "5px 0px",
          padding: "10px",
          boxShadow: "0px 3px 6px #00000029",
          height: "100px",
        }}
      >
        <img
          style={{ width: "30%", cursor: "pointer" }}
          alt="video_image"
          // src={ottVideo.image}
          src={
            ottVideo.cover_image ? ottVideo.cover_image : default_video_image
          }
          onClick={() => {
            navigate(`/ott/${ottVideo.id}`);
          }}
        />
        <div
          style={{
            width: "70%",
            display: "flex",
            flexWrap: "wrap",
            // "align-content": "space-between",
          }}
        >
          <div
            style={{
              padding: "0px 10px",
              cursor: "pointer",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-around",
            }}
            onClick={() => {
              navigate(`/ott/${ottVideo.id}`);
            }}
          >
            <span style={{ fontSize: "16px", fontWeight: 300 }}>
              {ottVideo.title.length <= STRING_LEN
                ? ottVideo.title
                : ottVideo.title.substring(0, STRING_LEN) + "..."}
            </span>

            <div style={{ display: "flex" }}>
              {/*<div style={{ marginRight: "10px" }}>
                <img style={{ height: "20px", }} src={Comment} />
                <span> 2</span>
              </div>*/}
              <div style={{ display: "flex", alignItems: "center" }}>
                {/* <FavoriteBorderOutlinedIcon sx={{ height: "25px" }} /> */}
                <img src={rating} style={{ height: "20px" }} />
                {/* <span className="likeCount">{likeCount} </span> */}
              </div>
            </div>

            <span
              style={{ fontSize: "12px", fontWeight: 300 }}
            >{`Directed by '${
              ottVideo.director_name ? ottVideo.director_name : "-"
            }'`}</span>

            <span style={{ fontSize: "12px", fontWeight: 300 }}>
              {`Cast : ${ottVideo.cast ? ottVideo.cast.join(", ") : "-"}`}
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default TopicVideo;
