import React from "react";

const Privacy = () => {
  return (
    <div style={{ height: "110px", padding: "1rem calc(-600px + 51vw)" }}>
      <header>
        <h1>Privacy Policy</h1>
      </header>
      <h3>Privacy Policy for Dhuniya App</h3>
      <p> Last updated: 23/01/2023</p>
      <ol>
        <li>
          This Privacy Policy outlines the practices of Dhuniya App ("Company,"
          "we," or "us") regarding the collection, use, and disclosure of
          personal information when you use our mobile application and related
          services (collectively, the "App").
        </li>
        <li>
          {" "}
          Information We Collect: (a) Personal Information: When you create an
          account, we may collect information such as your name, email address,
          and profile picture. If you choose to connect your social media
          accounts, we may collect information from those accounts as well. (b)
          Usage Information: We collect information about how you use the App,
          including your interactions with content, features, and
          advertisements. (c) Device Information: We automatically collect
          information about your device, including the device model, operating
          system, and unique device identifiers. (d) Location Information: With
          your consent, we may collect your precise location information to
          provide location-based services.
        </li>
        <li>
          {" "}
          How We Use Your Information: (a) Provide and Improve the App: We use
          your information to operate, maintain, and improve the App, including
          personalizing content and features. (b) Communication: We may use your
          contact information to send you updates, newsletters, or promotional
          materials. (c) Advertising: We may use your information to deliver
          targeted advertisements and promotions within the App.
        </li>
        <li>
          {" "}
          Information Sharing and Disclosure: (a) Service Providers: We may
          share your information with third-party service providers to perform
          functions on our behalf. (b) Legal Compliance: We may disclose your
          information to comply with applicable laws, regulations, or legal
          processes. (c) Business Transactions: In the event of a merger,
          acquisition, or sale of all or a portion of our assets, your
          information may be  transferred.
        </li>
        <li>
          {" "}
          Your Choices: (a) Account Information: You can update or delete your
          account information through the App's settings. (b) Location
          Information: You can disable location services in your device settings
          or through the App settings.
        </li>
        <li>
          {" "}
          Security: We take reasonable measures to protect your information, but
          no method of transmission over the internet or electronic storage is
          100% secure.
        </li>
        <li>
          {" "}
          Children's Privacy: The App is not intended for individuals under the
          age of 13. We do not knowingly collect personal information from
          children.
        </li>
        <li>
          {" "}
          Changes to this Privacy Policy: We may update this Privacy Policy from
          time to time. The date of the last update will be displayed at the top
          of the policy.
        </li>
        <li>
          {" "}
          Contact Us: If you have any questions or concerns about this Privacy
          Policy, please contact us at support@dhuniya.in.
        </li>
      </ol>
      <footer>
        <div class="container">
          <p>About Us</p>
          <nav>
            <ul>
              <li>
                <a href="terms.html">Terms and Conditions</a>
              </li>
              <li>
                <a href="privacy.html">Privacy Policy</a>
              </li>
              <li>
                <a href="/">Home</a>
              </li>
            </ul>
          </nav>
          <p>Contact: support@example.com</p>
        </div>
      </footer>
    </div>
  );
};

export default Privacy;
