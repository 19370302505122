import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getFullUserDetails } from "../../services/login";
import briefCaseIcon from "../../assests/vbc/briefcase.svg";
import emailIcon from "../../assests/vbc/email-2.svg";
import infoIcon from "../../assests/vbc/information-button.svg";
import mapsIcon from "../../assests/vbc/maps-and-flags.svg";
import phoneIcon from "../../assests/vbc/telephone-4.svg";
import webIcon from "../../assests/vbc/web.svg";
import addressIcon from "../../assests/vbc/_x34_7.svg";
import callIcon from "../../assests/vbc/callIcon.svg";
import whatsappIcon from "../../assests/vbc/whatsappIcon.svg";
import shareIcon from "../../assests/vbc/shareIcon.svg";
import locationIcon from "../../assests/vbc/locationIcon.svg";
import pdfIcon from "../../assests/vbc/pdf.svg";
import downloadIcon from "../../assests/vbc/download.svg";
import insta from "../../assests/vbc/insta.png";
import fb from "../../assests/vbc/fb.png";
import youtube from "../../assests/vbc/youtube.png";
import linkedin from "../../assests/vbc/linkedin.png";
import x_logo from "../../assests/vbc/X_Logo.png";
import pintrest from "../../assests/vbc/Pintrest.png";
import telegram from "../../assests/vbc/Telegram.png";
import BankAccountCard from "./bankAccount";
import UpiCard from "./upiCard";
import QRCard from "./qrCard";
import "./vbc.css";
import { Helmet } from "react-helmet";

const VirtualBusinessCard = () => {
  const [userValue, setUserValue] = React.useState();
  let { ref_id } = useParams();

  useEffect(() => {
    loadUserDetails();
  }, [ref_id]);

  const [value, setValue] = React.useState(window.location.href);

  const notify = () => toast("Link Copied");

  const onCopy = React.useCallback(() => {
    console.log(value, "copy clicked");
    notify();
  }, []);

  const loadUserDetails = async () => {
    const userValue = await getFullUserDetails(ref_id);
    setUserValue(userValue);
    // setLikeCount(ott_videos.likes_count);
    // setLiked(await isVideoLiked(ott_videos.id));
  };

  const splitTextFromLastMatchItem = (text, separator) => {
    if (!text) return "";
    // Find the last index of the separator in the text.
    const lastIndex = text.lastIndexOf(separator);

    // If the separator is not found, return the original text.
    if (lastIndex === -1) {
      return text;
    }

    // Split the text at the last index of the separator.
    const parts = text.split(separator, lastIndex + 1);

    // Return the last part of the split text.
    return `${parts[parts.length - 1].substring(0, 30)}....`;
  };
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Helmet>
        <meta
          name="description"
          content="Checkout my Virtual Business Visiting Card on Dhuniya!"
        />
      </Helmet>

      <div style={{ maxWidth: "400px" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <p>VIRTUAL BUSINESS CARD</p>
          <img
            style={{
              borderRadius: "200px",
              border: "1px solid #81b3ea",
              aspectRatio: 1 / 1,
              width: "25%",
            }}
            alt="profile_pic"
            src={userValue?.data?.info?.card?.profile_picture}
          />
          <span style={{ fontWeight: "bold", margin: "3% 2% 1%" }}>
            {userValue?.data?.info?.card?.full_name}
          </span>
          <span style={{ margin: "1%" }}>
            {userValue?.data?.info?.card?.designation}
          </span>
          <span style={{ margin: "1% 1% 5%" }}>
            {userValue?.data?.info?.card?.brand_name}
          </span>
        </div>

        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            marginBottom: "4%",
          }}
        >
          <div
            style={{
              width: "40%",
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "row",
            }}
          >
            <Link to={`tel:${userValue?.data?.info?.card?.mobile}`}>
              <img style={{ width: "30px" }} src={callIcon} alt="" />
            </Link>
            <Link
              to={`https://wa.me/+91${userValue?.data?.info?.card?.mobile}`}
            >
              <img style={{ width: "30px" }} src={whatsappIcon} alt="" />
            </Link>
            <Link to={userValue?.data?.info?.card?.maps_link}>
              <img style={{ width: "30px" }} src={locationIcon} alt="" />
            </Link>
            <CopyToClipboard onCopy={onCopy} text={value}>
              <img style={{ width: "30px" }} src={shareIcon} alt="" />
            </CopyToClipboard>
            <ToastContainer autoClose={2000} />
          </div>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div
            style={{
              width: "90%",
              justifyContent: "space-between",
              display: "flex",
              alignItems: "flex-start",
              marginTop: "1%",
            }}
          >
            <img style={{ width: "5%" }} src={briefCaseIcon} alt="" />
            <span style={{ width: "25%" }} className="details_title">
              Business Name:
            </span>
            <span
              style={{ width: "65%", marginBottom: "2%" }}
              className="details_description"
            >
              {userValue?.data?.info?.card?.legal_name}
            </span>
          </div>

          <div
            style={{
              width: "90%",
              justifyContent: "space-between",
              display: "flex",
              alignItems: "flex-start",
              marginTop: "1%",
            }}
          >
            <img style={{ width: "5%" }} src={infoIcon} alt="" />
            <span style={{ width: "25%" }} className="details_title">
              Business Nature:
            </span>
            <span
              style={{ width: "65%", marginBottom: "2%" }}
              className="details_description"
            >
              {userValue?.data?.info?.card?.nature_of_business}
            </span>
          </div>

          <div
            style={{
              width: "90%",
              justifyContent: "space-between",
              display: "flex",
              alignItems: "flex-start",
              marginTop: "1%",
            }}
          >
            <img style={{ width: "5%" }} src={webIcon} alt="" />
            <span style={{ width: "25%" }} className="details_title">
              Website:
            </span>
            <span
              style={{ width: "65%", marginBottom: "2%" }}
              className="details_description"
            >
              {userValue?.data?.info?.card?.website}
            </span>
          </div>

          <div
            style={{
              width: "90%",
              justifyContent: "space-between",
              display: "flex",
              alignItems: "flex-start",
              marginTop: "1%",
            }}
          >
            <img style={{ width: "5%" }} src={addressIcon} alt="" />
            <span style={{ width: "25%" }} className="details_title">
              Address:
            </span>
            <span
              style={{ width: "65%", marginBottom: "2%" }}
              className="details_description"
            >
              {userValue?.data?.info?.card?.address}
            </span>
          </div>

          <div
            style={{
              width: "90%",
              justifyContent: "space-between",
              display: "flex",
              alignItems: "flex-start",
              marginTop: "1%",
            }}
          >
            <img style={{ width: "5%" }} src={phoneIcon} alt="" />
            <span style={{ width: "25%" }} className="details_title">
              Phone:
            </span>
            <span
              style={{ width: "65%", marginBottom: "2%" }}
              className="details_description"
            >
              {userValue?.data?.info?.card?.mobile}
            </span>
          </div>

          <div
            style={{
              width: "90%",
              justifyContent: "space-between",
              display: "flex",
              alignItems: "flex-start",
              marginTop: "1%",
            }}
          >
            <img style={{ width: "5%" }} src={emailIcon} alt="" />
            <span style={{ width: "25%" }} className="details_title">
              Email:
            </span>
            <span
              style={{ width: "65%", marginBottom: "2%" }}
              className="details_description"
            >
              {userValue?.data?.info?.card?.email}
            </span>
          </div>
          <div
            style={{
              width: "90%",
              justifyContent: "space-between",
              display: "flex",
              alignItems: "flex-start",
              marginTop: "1%",
            }}
          >
            <img style={{ width: "5%" }} src={mapsIcon} alt="" />
            <span style={{ width: "25%" }} className="details_title">
              Maps:
            </span>
            <span
              style={{ width: "65%", marginBottom: "2%" }}
              className="details_description"
            >
              <a href={userValue?.data?.info?.card?.maps_link}>
                {userValue?.data?.info?.card?.maps_link
                  ? userValue?.data?.info?.card?.maps_link.substring(0, 50) +
                    "..."
                  : ""}
              </a>
            </span>
          </div>
        </div>

        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            marginBottom: "2%",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <p style={{ color: "#0280FF", fontSize: "20px", fontWeight: 300 }}>
            SOCIAL MEDIA
          </p>

          <div
            style={{
              width: "100%",
              display: "flex",
              // flexDirection: "row",
              justifyContent: "center",
            }}
          >
            {userValue?.data?.info?.card?.instagram_link && (
              <Link
                to={userValue?.data?.info?.card?.instagram_link}
                style={{ marginRight: "3%" }}
              >
                <img style={{ width: "30px" }} src={insta} alt="" />
              </Link>
            )}
            {userValue?.data?.info?.card?.facebook_link && (
              <Link
                to={userValue?.data?.info?.card?.facebook_link}
                style={{ marginRight: "3%" }}
              >
                <img style={{ width: "30px" }} src={fb} alt="" />
              </Link>
            )}
            {userValue?.data?.info?.card?.youtube_link && (
              <Link
                to={userValue?.data?.info?.card?.youtube_link}
                style={{ marginRight: "3%" }}
              >
                <img style={{ width: "30px" }} src={youtube} alt="" />
              </Link>
            )}
            {userValue?.data?.info?.card?.linkedin_link && (
              <Link
                to={userValue?.data?.info?.card?.linkedin_link}
                style={{ marginRight: "3%" }}
              >
                <img style={{ width: "30px" }} src={linkedin} alt="" />
              </Link>
            )}
            {userValue?.data?.info?.card?.twitter_link && (
              <Link
                to={userValue?.data?.info?.card?.twitter_link}
                style={{ marginRight: "3%" }}
              >
                <img style={{ width: "30px" }} src={x_logo} alt="" />
              </Link>
            )}
            {userValue?.data?.info?.card?.pintrest_link && (
              <Link
                to={userValue?.data?.info?.card?.pintrest_link}
                style={{ marginRight: "3%" }}
              >
                <img style={{ width: "30px" }} src={pintrest} alt="" />
              </Link>
            )}
            {userValue?.data?.info?.card?.telegram_link && (
              <Link
                to={userValue?.data?.info?.card?.telegram_link}
                style={{ marginRight: "3%" }}
              >
                <img style={{ width: "30px" }} src={telegram} alt="" />
              </Link>
            )}
          </div>
        </div>

        {userValue?.data?.info?.accounts && (
          <div
            style={{
              width: "100%",
              justifyContent: "center",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              padding: "2%",
            }}
          >
            <p style={{ color: "#0280FF", fontSize: "20px", fontWeight: 300 }}>
              PAYMENT INFORMATION
            </p>

            {userValue?.data?.info?.accounts?.map((item, index) => {
              return (
                <div
                  style={{
                    width: "94%",
                    justifyContent: "space-between",
                    display: "flex",
                    padding: "3%",
                    marginBottom: "2%",
                    boxShadow: "0px 3px 6px #00000029",
                  }}
                >
                  {item.account_number ? (
                    <BankAccountCard item={item} />
                  ) : item.upi_id ? (
                    <UpiCard item={item} />
                  ) : item.qr_code ? (
                    <QRCard item={item} />
                  ) : (
                    ""
                  )}
                </div>
              );
            })}
          </div>
        )}

        {userValue?.data?.info?.card?.about_business && (
          <div
            style={{
              width: "94%",
              justifyContent: "space-between",
              display: "flex",
              padding: "3%",
              margin: "2% 2% 5%",
              boxShadow: "0px 3px 6px #00000029",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <span
              style={{ color: "#0280FF", fontSize: "20px", fontWeight: 300 }}
            >
              ABOUT BUSINESS
            </span>
            {/* <p>{userValue?.data?.info?.card?.about_business}</p> */}
            <p style={{ fontWeight: 400, fontSize: "14px" }}>
              {userValue?.data?.info?.card?.about_business}
            </p>
          </div>
        )}

        {userValue?.data?.info?.services && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
              marginBottom: "5%",
            }}
          >
            <span
              style={{ color: "#0280FF", fontSize: "20px", fontWeight: 300 }}
            >
              CATALOGUE
            </span>
            {userValue?.data?.info?.services?.map((item, index) => {
              return (
                <div
                  style={{
                    width: "90%",
                    justifyContent: "space-between",
                    display: "flex",
                    padding: "3%",
                    margin: "2%",
                    boxShadow: "0px 3px 6px #00000029",
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <strong>{item.service_name}</strong>
                    <p>{item.service_description}</p>
                    <img
                      width={"80%"}
                      src={item.service_images?.[0]}
                      alt="srvc_image"
                    />
                    <strong>Price: {item.pricing}</strong>
                  </div>
                </div>
              );
            })}
          </div>
        )}

        {userValue?.data?.info?.card?.document_pdf && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <span
              style={{ color: "#0280FF", fontSize: "20px", fontWeight: 300 }}
            >
              DOCUMENTS
            </span>

            <div
              style={{
                width: "90%",
                justifyContent: "space-between",
                display: "flex",
                padding: "3%",
                margin: "2% 2% 5%",
                boxShadow: "0px 3px 6px #00000029",
              }}
            >
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <img width={"8%"} src={pdfIcon} alt="dwld_image" />
                <span
                  style={{
                    width: "80%",
                    paddingLeft: "5%",
                    fontWeight: 300,
                    fontSize: "14px",
                  }}
                >
                  {splitTextFromLastMatchItem(
                    userValue?.data?.info?.card?.document_pdf,
                    "/"
                  )}
                </span>
                <Link
                  width={"8%"}
                  to={userValue?.data?.info?.card?.document_pdf}
                  target="_blank"
                  download
                >
                  <img src={downloadIcon} alt="dwld_image" />
                </Link>
              </div>
            </div>
          </div>
        )}

        {userValue?.data?.info?.card?.document_pictures && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
              marginBottom: "5%",
            }}
          >
            <span
              style={{ color: "#0280FF", fontSize: "20px", fontWeight: 300 }}
            >
              GALLERY
            </span>

            <div
              style={{
                width: "90%",
                display: "flex",
                padding: "3%",
                margin: "2% 2% 5%",
                boxShadow: "0px 3px 6px #00000029",
                flexWrap: "wrap",
              }}
            >
              {userValue?.data?.info?.card?.document_pictures?.map(
                (item, index) => {
                  return (
                    <div
                      style={{
                        width: "50%",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <img width={"100%"} src={item} alt="srvc_image" />
                    </div>
                  );
                }
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default VirtualBusinessCard;
