import React, { useEffect, useState } from "react";
import "@fontsource/lexend"; // Defaults to weight 400
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import LoginButton from "../../../login";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { useParams } from "react-router-dom";
import { getFullVideo } from "../../../../services/ott";
import OttPlayer from "../../ottPlayer";

// import {
//   getFullVideo,
//   likeNewsVideo,
//   dislikeNewsVideo,
//   isVideoLiked,
// } from "../../../../services/ott";
import default_video_image from "../../../../assests/default_video_image.jpeg";
import "../ottTopics.css";
import Topic from "../ottTopics";
import { useNavigate } from "react-router-dom";
import rating from "../../../../assests/movie_rating.svg";
import directorIcon from "../../../../assests/director-chair.svg";
import movieIcon from "../../../../assests/movie.svg";
import closedCaption from "../../../../assests/closed-caption.svg";
import speaker from "../../../../assests/speaker.svg";

const FullVideo = () => {
  const navigate = useNavigate();

  let { video_id } = useParams();
  const [video, set_video] = useState();
  // const [likeCount, setLikeCount] = useState(0);
  // const [liked, setLiked] = useState(false);

  useEffect(() => {
    loadVideo();
  }, [video_id]);

  const loadVideo = async () => {
    const ott_videos = await getFullVideo(video_id);
    set_video(ott_videos);
    // setLikeCount(ott_videos.likes_count);
    // setLiked(await isVideoLiked(ott_videos.id));
  };

  // const updateLike = async () => {
  //   if (liked) {
  //     setLiked(false);
  //     setLikeCount(likeCount - 1);
  //     dislikeNewsVideo(video.id);
  //   } else {
  //     setLiked(true);
  //     setLikeCount(likeCount + 1);
  //     likeNewsVideo(video.id);
  //   }
  // };

  return (
    <div
      className="lexend-regular"
      style={{
        typography: "body1",
        padding: "0.2rem calc((102vw - 1200px) / 2)",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Breadcrumbs
          sx={{ padding: "2%" }}
          separator={<NavigateNextIcon fontSize="medium" />}
          aria-label="breadcrumb"
        >
          <Link underline="hover" color="inherit" href="/ott">
            OTT
          </Link>
          {video?.genres?.[0] && (
            <Link
              underline="hover"
              color="inherit"
              href={`/ott?genres=${video.genres?.[0]}`}
            >
              {video.genres?.[0]}
            </Link>
          )}
          <Typography color="text.primary">{video?.title}</Typography>
        </Breadcrumbs>
        <LoginButton className="loginButton" />
      </div>
      {video && (
        <div
          className="fullVideoBlock"
          style={{ display: "flex", alignItems: "flex-start" }}
        >
          <div
            className="fullVideo"
            style={{
              width: "50%",
              padding: "1% 2%",
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            {/* {video.image && (
              <img
                style={{ height: "40vh", margin: "2% 0%" }}
                src={video.image ? video.image : default_video_image}
              />
            )}
            {video.video && (
              <video style={{ height: "40vh", margin: "2% 0%" }} controls>
                <source src={video?.video?.[0]} type="video/mp4" />
              </video>
            )} */}
            <OttPlayer url={video?.movie_data?.converted_url} />
            <strong
              style={{
                fontSize: "16px",
                fontWeight: 600,
                margin: "1% 0% 2% 0%",
              }}
            >
              {video.title}
            </strong>
            <div
              style={{
                fontSize: "14px",
                fontWeight: 300,
                display: "flex",
                alignItems: "center",
                marginBottom: "3%",
              }}
            >
              <img src={rating} style={{ height: "20px" }} />
              <span> &nbsp; {video?.release_date}&nbsp;|</span>
              <span> &nbsp; {video?.genres}&nbsp;|</span>
              <span> &nbsp; {video?.duration_in_mins} mins&nbsp;|</span>
              <span> &nbsp; {video?.language} </span>
            </div>

            {video.movie_maker_data && (
              <div style={{ display: "flex", flexDirection: "column" }}>
                <strong
                  style={{
                    fontSize: "16px",
                    fontWeight: 600,
                    marginBottom: "2%",
                  }}
                >
                  About the Creators
                </strong>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <img
                    src={video.movie_maker_data.logo}
                    style={{ height: "30px", marginRight: "2%" }}
                  />
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <span style={{ fontSize: "14px" }}>
                      {video.movie_maker_data.name}
                    </span>
                    <span style={{ fontSize: "12px", fontWeight: 300 }}>
                      {video.movie_maker_data.description}
                    </span>
                  </div>
                </div>
              </div>
            )}

            <div
              style={{
                marginTop: "1%",
                fontSize: "14px",
                fontWeight: 300,
                display: "flex",
                flexDirection: "column",
                marginBottom: "2%",
              }}
            >
              <div
                style={{
                  marginTop: "2%",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    width: "30%",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={directorIcon}
                    style={{ height: "20px", marginRight: "5%" }}
                  />
                  <span style={{ color: "#0A2647" }}> Director : </span>
                </div>
                <span> {video?.director_name}</span>
              </div>

              <div
                style={{
                  marginTop: "2%",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    width: "30%",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={movieIcon}
                    style={{ height: "20px", marginRight: "5%" }}
                  />
                  <span style={{ color: "#0A2647" }}> Cast : </span>
                </div>
                <span> {video?.cast.join(", ")}</span>
              </div>

              <div
                style={{
                  marginTop: "2%",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    width: "30%",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={speaker}
                    style={{ height: "20px", marginRight: "5%" }}
                  />
                  <span style={{ color: "#0A2647" }}> Audio : </span>
                </div>
                <span> {video?.language}</span>
              </div>

              <div
                style={{
                  marginTop: "2%",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    width: "30%",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={closedCaption}
                    style={{ height: "20px", marginRight: "5%" }}
                  />
                  <span style={{ color: "#0A2647" }}> Subtitles : </span>
                </div>
                <span> {video?.language}</span>
              </div>
            </div>

            <div
              style={{
                fontSize: "13px",
                fontWeight: 300,
                display: "flex",
                alignItems: "flex-start",
                flexDirection: "column",
                color: "#0A2647",
              }}
            >
              <span style={{ fontSize: "14px", margin: "1% 0%" }}>
                Synopsis
              </span>
              <span style={{ paddingBottom: "1%" }}>{video.synopsis}</span>
            </div>

            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              {/* <img
                src={heart}
                style={{ height: "18px", margin: "10px 5px" }}
                // onClick={() => {
                //   updateLike();
                // }}
              /> */}
              {/* <span className="likeCount">{likeCount} </span> */}
            </div>
          </div>

          <div
            className="fullVideo"
            style={{
              width: "50%",
            }}
          >
            <Topic
              topic_name={video?.genres?.[0]}
              categories={video?.genres?.[0]}
              title={`More in ${video?.genres?.[0]}`}
              count={4}
              switchTab={() => {
                navigate(`/ott?genres=${video.genres?.[0]}`);
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
};
export default FullVideo;
