import React from "react";
import "@fontsource/lexend"; // Defaults to weight 400
import "./App.css";
// import Navbar from "./components/navbar";
import Navbar from "./components/Navbar";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import News from "./pages/news";
import Ott from "./pages/ott";
import FullVideo from "./pages/ott/ottMain/videos/fullVideo";
import OttPlayer from "./pages/ott/ottPlayer";
import FindBusiness from "./pages/find_business";
import VBC from "./pages/vbc";
import Privacy from "./pages/private";
import Terms from "./pages/terms";

import { Global, css } from "@emotion/react";
import FullArticle from "./pages/news/forYou/articles/fullArticle";

// const styles = {
//   container: {
//     display: "flex",
//     flexDirection: "column",
//     alignItems: "center",
//     justifyContent: "center",
//     height: "100vh",
//   },
//   heading: {
//     fontSize: "2rem",
//     fontWeight: "bold",
//     marginBottom: "20px",
//   },
//   paragraph: {
//     fontSize: "1rem",
//     maxWidth: "400px",
//     textAlign: "center",
//   },
// };

// const TeluguStyles = () => (
//   <Global
//     styles={css`
//       /* Apply global styles */
//       body {
//         margin: 0;
//         padding: 0;
//         background-color: #ffffff;
//         font-family: "Noto Sans Telugu", sans-serif !important;
//       }
//     `}
//   />
// );

// const GlobalStyles = () => (
//   <Global
//     styles={css`
//       /* Apply global styles */
//       body {
//         margin: 0;
//         padding: 0;
//         background-color: #ffffff;
//         font-family: "Lexend", Regular !important;
//       }
//     `}
//   />
// );

function App() {
  return (
    <>
      {/* <GlobalStyles /> */}
      {/* <TeluguStyles /> */}

      <Router>
        <Navbar />
        <Routes>
          <Route path="/" element={<News />} />
          <Route path="/news">
            <Route path=":article_id" element={<FullArticle />} />
            <Route path="" element={<News />} />
          </Route>
          <Route path="/ott">
            <Route path=":video_id" element={<FullVideo />} />
            <Route path="" element={<Ott />} />
          </Route>
          <Route path="/ottPlayer" element={<OttPlayer />} />
          <Route path="/fb" element={<FindBusiness />} />
          <Route path="/vbc/:ref_id" element={<VBC />} />
          <Route path="/privacy.html" element={<Privacy />} />
          <Route path="/terms.html" element={<Terms />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
