import React, { useState, useEffect } from "react";
// import PasswordInput from "react-password-input";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import Slide from "@mui/material/Slide";
import CloseIcon from "@mui/icons-material/Close";
import "@fontsource/lexend"; // Defaults to weight 400
import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import "./dialog.css";
import Divider from "@mui/material/Divider";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function PasswordPopup({
  openPassword,
  handlePasswordClose,
  handlePasswordSubmit,
  mobileNumber,
  error,
  setError,
}) {
  const [password, setPassword] = useState("");
  const [pwdError, setPwdError] = useState();
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = React.useState(false);
  const [validPassword, setValidPassword] = useState(false);

  // useEffect(() => {
  //   checkPwd(password);
  // }, [password]);

  const buttonStyles = validPassword
    ? {
        display: "flex",
        width: "90%",
        padding: "15px",
        margin: "0px 0px 30px",
        backgroundColor: "#077efa",
        color: "white",
        boxShadow: "0px 2px 4px #00000029",
        borderRadius: "50px",
      }
    : {
        display: "flex",
        width: "90%",
        padding: "15px",
        margin: "0px 0px 30px",
        backgroundColor: "#F3F6F9",
        color: "#D6D6D6",
        boxShadow: "0px 2px 4px #00000029",
        borderRadius: "50px",
      };

  const checkPwd = async (password) => {
    let errors = [];
    if (password.length < 8) {
      errors.push("Too short");
    }
    if (password.search(/\d/) == -1) {
      errors.push("No number");
    }
    if (password.search(/[a-zA-Z]/) == -1) {
      errors.push("No Letter");
    }
    setPwdError(errors);
  };

  const clickProceed = async () => {
    handlePasswordSubmit(mobileNumber, password);
  };

  const checkProceed = async (password, confirmPassword) => {
    checkPwd(password);
    if (password && password === confirmPassword && pwdError.length === 0) {
      setValidPassword(true);
    } else {
      setValidPassword(false);
    }
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  return (
    <>
      <Dialog
        sx={{
          "& .MuiTextField-root": { m: 2 },
          "& .MuiPaper-root": {
            borderRadius: "30px",
          },
        }}
        open={openPassword}
        TransitionComponent={Transition}
        keepMounted
        onClose={handlePasswordClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <div
          className="alert-dialog"
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              padding: "5%",
            }}
          >
            <span
              style={{
                margin: "auto",
                textAlign: "center",
                fontSize: "20px",
              }}
            >
              {" "}
              Set Password
            </span>
            <CloseIcon
              style={{ position: "absolute", top: "16px", right: "16px" }}
              onClick={handlePasswordClose}
            />
          </div>
          <Divider style={{ width: "100%" }} />

          <span
            style={{
              width: "80%",
              margin: "5% 10% 5%",
              fontSize: "15px",
              opacity: "0.7",
            }}
          >
            Type in your Password
          </span>
          <FormControl sx={{ m: 1, width: "80%" }} variant="outlined">
            <InputLabel htmlFor="outlined-adornment-password">
              Password
            </InputLabel>
            <OutlinedInput
              id="outlined-adornment-password"
              type={showPassword ? "text" : "password"}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              onChange={async (event) => {
                setPassword(event.target.value);
                await checkPwd(event.target.value);
                checkProceed(event.target.value, confirmPassword);
              }}
              label="Password"
            />
          </FormControl>
          {error && (
            <span
              style={{
                padding: "3%",
                fontSize: "12px",
                color: "red",
              }}
            >
              {error}
            </span>
          )}
          {pwdError && (
            <span
              style={{
                padding: "3%",
                fontSize: "12px",
                color: "red",
              }}
            >
              {pwdError}
            </span>
          )}
          <span
            style={{
              width: "80%",
              margin: "5% 10% 5%",
              fontSize: "13px",
              opacity: "0.7",
            }}
          >
            Password must contain a minimum of 8 Characters with at least 1
            Alphabet and 1 Number
          </span>
          <FormControl sx={{ m: 1, width: "80%" }} variant="outlined">
            <InputLabel htmlFor="outlined-adornment-confirmpassword">
              Confirm New Password
            </InputLabel>
            <OutlinedInput
              id="outlined-adornment-confirmpassword"
              type={showPassword ? "text" : "password"}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              onChange={(event) => {
                setConfirmPassword(event.target.value);
                checkProceed(password, event.target.value);
              }}
              label="Confirm New Password"
            />
          </FormControl>
          <DialogActions sx={{ justifyContent: "center", width: "80%" }}>
            <Button
              style={buttonStyles}
              disabled={!validPassword}
              onClick={clickProceed}
            >
              <strong>Proceed</strong>
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    </>
  );
}
