import React, { useState } from "react";
// import ProfileInput from "react-profiled-input";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import Slide from "@mui/material/Slide";
import CloseIcon from "@mui/icons-material/Close";
import "@fontsource/lexend"; // Defaults to weight 400
import verifyTick from "../../assests/verifyTick.png";
import ProfileEditIcon from "../../assests/ProfileEditIcon.png";
import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import "./dialog.css";
import DefaultPic from "../../assests/profile-pic.png";
import Divider from "@mui/material/Divider";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function PasswordLogin({
  userDetails,
  openPasswordLogin,
  handlePasswordLoginClose,
  handlePasswordLogin,
  mobileNumber,
  error,
}) {
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = React.useState(false);

  const buttonStyles = {
    display: "flex",
    width: "90%",
    padding: "15px",
    margin: "0px 0px 30px",
    backgroundColor: "#077efa",
    color: "white",
    boxShadow: "0px 2px 4px #00000029",
    borderRadius: "50px",
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const clickProceed = async () => {
    handlePasswordLogin(mobileNumber, password);
  };

  return (
    <>
      <Dialog
        sx={{
          "& .MuiTextField-root": { m: 2 },
          "& .MuiPaper-root": {
            borderRadius: "30px",
          },
        }}
        open={openPasswordLogin}
        TransitionComponent={Transition}
        keepMounted
        onClose={handlePasswordLoginClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <div
          className="alert-dialog"
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              padding: "5%",
            }}
          >
            <span
              style={{
                margin: "auto",
                textAlign: "center",
                fontSize: "20px",
              }}
            >
              {" "}
              Enter Password
            </span>
            <CloseIcon
              style={{ position: "absolute", top: "16px", right: "16px" }}
              onClick={handlePasswordLoginClose}
            />
          </div>

          <Divider style={{ width: "100%" }} />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "space-between",
              marginTop: "6%",
            }}
          >
            <span style={{ fontWeight: 300 }}>Welcome Back !</span>
            <img
              style={{
                borderRadius: "50px",
                border: "2px solid #81b3ea",
                margin: "5%",
              }}
              width="15%"
              alt="profile_pic"
              src={
                userDetails?.profile_image
                  ? userDetails?.profile_image
                  : DefaultPic
              }
            />
            <span style={{ fontWeight: 400, marginBottom: "3%" }}>
              {userDetails?.username}
            </span>
            {/* <div>
              <div>
                <img
                  alt="tick"
                  style={{ width: "15px" }}
                  src={verifyTick}
                ></img>
              </div>
            </div> */}
          </div>
          <FormControl sx={{ m: 1, width: "80%" }} variant="outlined">
            <InputLabel htmlFor="outlined-adornment-password">
              Password
            </InputLabel>
            <OutlinedInput
              id="outlined-adornment-password"
              type={showPassword ? "text" : "password"}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              onChange={(event) => {
                setPassword(event.target.value);
              }}
              label="Password"
            />
          </FormControl>
          {error && (
            <span
              style={{
                padding: "3%",
                fontSize: "12px",
                color: "red",
              }}
            >
              {error}
            </span>
          )}
          <span
            style={{
              color: "#0280FF",
              fontSize: "14px",
              width: "80%",
              display: "flex",
              flexDirection: "row-reverse",
              marginBottom: "5%",
            }}
          >
            Forgot Password ?
          </span>

          <DialogActions sx={{ justifyContent: "center", width: "80%" }}>
            <Button style={buttonStyles} onClick={clickProceed}>
              <strong>Proceed</strong>
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    </>
  );
}
