import React from "react";
import Banner from "./banner";
import Topic from "./ottTopics";
import "../ott.css";
import { getCategoriesData } from "../../../services/ott";
import { useNavigate } from "react-router-dom";

const OttMain = ({}) => {
  const [categories, setCategories] = React.useState([]);
  const navigate = useNavigate();

  const loadCategoriesData = async () => {
    const categoriesData = await getCategoriesData();
    setCategories(categoriesData.slice(0, 4));
  };

  React.useEffect(() => {
    loadCategoriesData();
  }, []);

  // const categories = [
  //   { displayname: "Politics", categories: "POLITICS" },
  //   { displayname: "Sports", categories: "SPORTS" },
  //   { displayname: "Movies", categories: "MOVIE" },
  //   { displayname: "Entertainment", categories: "ENTERTAINMENT" },
  //   // { displayname: "Art", categories: "ART" },
  // ];

  return (
    <>
      <Banner />
      <div className="grid-container">
        {categories.map((category) => {
          return (
            <Topic
              key={category.name}
              id={category.title}
              topic_name={category.display_name}
              title={category.display_name}
              categories={category.name}
              category={category}
              switchTab={() => {
                navigate(`/ott?genres=${category.name}`);
                window.location.reload();
              }}
              count={3}
            />
          );
        })}
      </div>
    </>
  );
};

export default OttMain;
