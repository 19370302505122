import React from "react";
import { dateConverter } from "../../../utils";
import heart from "../../../assests/heart.svg";
import default_article_image from "../../../assests/default_article_image.jpeg";
import {
  likeNewsArticle,
  dislikeNewsArticle,
  isArticleLiked,
} from "../../../services/news";

import "../news.css";
import { useNavigate } from "react-router-dom";

const STRING_LEN = 50;
const CON_LEN = 175;

const Article = ({ article }) => {
  const navigate = useNavigate();
  const [likeCount, setLikeCount] = React.useState(0);
  const [liked, setLiked] = React.useState(false);

  React.useEffect(() => {
    loadArticle();
  }, []);

  const loadArticle = async () => {
    setLikeCount(article.likes_count);
    setLiked(await isArticleLiked(article.id));
  };

  const updateLike = async () => {
    if (liked) {
      setLiked(false);
      setLikeCount(likeCount - 1);
      dislikeNewsArticle(article.id);
    } else {
      setLiked(true);
      setLikeCount(likeCount + 1);
      likeNewsArticle(article.id);
    }
  };

  return (
    <div
      className="lexend-regular"
      style={{
        display: "flex",
        margin: "2% 0%",
        padding: "1%",
        height: "7em",
        boxShadow: "0px 3px 6px #00000029",
      }}
    >
      <img
        alt="article_image"
        onClick={() => {
          navigate(`/news/${article.ref_id}`);
        }}
        style={{ width: "20%", cursor: "pointer" }}
        // src={article.image}
        src={
          article.image && article.image.length > 0
            ? article.image
            : default_article_image
        }
      />
      {/*<img style={{"width":"20%"}} src="https://t3.ftcdn.net/jpg/03/27/55/60/360_F_327556002_99c7QmZmwocLwF7ywQ68ChZaBry1DbtD.jpg"/>*/}
      <div
        style={{
          width: "80%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          paddingLeft: "3%",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "baseline",
            cursor: "pointer",
          }}
          onClick={() => {
            navigate(`/news/${article.ref_id}`);
          }}
        >
          <strong>
            {article.title !== undefined && article.title.length <= STRING_LEN
              ? article.title
              : article.title.substring(0, STRING_LEN) + "..."}
          </strong>

          <div style={{ display: "flex" }}>
            <span className="half_opacity">
              {dateConverter(article.created_date)}
            </span>
            {/* <BookmarkBorderIcon /> */}
          </div>
        </div>

        <span
          style={{ cursor: "pointer" }}
          onClick={() => {
            navigate(`/news/${article.ref_id}`);
          }}
        >
          {article.description !== undefined &&
          article.description.length <= CON_LEN
            ? article.description
            : article.description.substring(0, CON_LEN) + "... "}
        </span>

        <div style={{ display: "flex" }}>
          <div
            style={{
              marginRight: "3%",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <img
              src={heart}
              style={{ height: "15px", marginRight: "5px" }}
              onClick={() => {
                updateLike();
              }}
            />
            <span className="likeCount">{likeCount} </span>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Article;
